import { createClient } from "pexels";

const client = createClient(process.env.REACT_APP_PEXELS_API_KEY);

const searchForPhotos = async (query) => {
  try {
    const result = await client.photos.search({ query, per_page: 12, orientation: "landscape" });
    return result;
    // console.log(result);
  } catch (error) {
    alert(error.message);
  }
};

export { searchForPhotos };
