import {
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  Button,
  AlertIcon,
  AlertTitle,
  Alert,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tooltip,
  Text,
  Box,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState } from "react";

import "./ImageResult.css";

import moment from "moment";
import { firebase, db } from "../../../firebase";
import { collection, addDoc } from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { searchForPhotos } from "../../api/Pexels";

const ImageResult = ({ url, selectedImage, setSelectedImage, index, setImageUrl }) => {
  const handleSelected = () => {
    setSelectedImage(index);
    setImageUrl(url);
  };

  return (
    <Box
      mb={1}
      cursor="pointer"
      className="food-img"
      onClick={handleSelected}
      // borderWidth={selectedImage === index ? 2 : null}
      // borderColor={selectedImage === index ? "blue.400" : null}
      boxShadow={selectedImage === index ? "0 0px 5px 5px #63B3ED" : null}
      style={{ tintColor: "gray" }}
    >
      <Image src={url} objectFit="cover" boxSize="150px" />
    </Box>
  );
};

export default function NewItemForm({ getAllMenuItems }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [cost, setCost] = useState();
  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [searchedImages, setSearchedImages] = useState();
  const [error, setError] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [noImgResults, setNoImgResults] = useState(false);

  const formElementSize = useBreakpointValue(["md", "lg"]);
  const buttonWidth = useBreakpointValue(["100%", "15%"]);

  const handleClose = () => {
    setSearchedImages();
    setNoImgResults(false);
    onClose();
  };

  const resetFields = () => {
    setName();
    setDescription();
    setCost();
    setCategory();
    setImageUrl();
    setSearchedImages();
  };

  const handleImageSearch = async () => {
    if (name) {
      try {
        const result = await searchForPhotos(name);
        if (result.photos.length > 0) {
          setNoImgResults(false);
          setSearchedImages(result.photos);
        } else {
          setSearchedImages();
          setNoImgResults(true);
        }
      } catch (error) {
        alert(error.message);
      }
    } else {
      alert("add a food name");
    }
  };

  const upperCaseThis = (text) => {
    return text.toUpperCase();
  };

  const validateFields = () => {
    if (!name) {
      alert("Add a name for the food item.");
      setLoading(false);
      return false;
    }
    if (!cost) {
      alert("Add a cost for the food item.");
      setLoading(false);
      return false;
    }
    if (cost.includes("$")) {
      alert('Please remove the "$" in the cost.');
      setLoading(false);
      return false;
    }
    if (!category) {
      alert("Select a category for the food item.");
      setLoading(false);
      return false;
    }
    if (description.includes('"') || description.includes("'")) {
      alert("Please remove any single or double quotation marks inside the description.");
      setLoading(false);
      return false;
    }
    return true;
  };

  const addNewMenuItem = async () => {
    setLoading(true);
    setSuccess(false);
    setError(false);

    if (!validateFields()) {
      return;
    }

    const data = {
      name: upperCaseThis(name),
      description: description ? upperCaseThis(description) : null,
      cost,
      category,
      created: moment().format("l LT"),
      url: imageUrl,
    };

    console.log(data);

    try {
      const result = await addDoc(collection(db, "menu"), data);
      setSuccess(true);
      setError(false);
      setTimeout(() => {
        resetFields();
        setSuccess(false);
        setLoading(false);
        getAllMenuItems();
        onClose();
      }, 2000);
    } catch (error) {
      setSuccess(false);
      setError(true);
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Tooltip hasArrow placement="top" label="Add a new item to the menu.">
        <Button mr={1} colorScheme="blue" onClick={onOpen} size={formElementSize} w={buttonWidth}>
          <Text letterSpacing={3}>NEW ITEM</Text>
        </Button>
      </Tooltip>

      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        isCentered
        size="2xl"
        scrollBehavior="inside"
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader onClick={() => console.log(upperCaseThis(imageUrl))}>
            New Menu Item
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justify="center" alignSelf="center" flexDir="column">
              <FormControl my={3}>
                <FormLabel>Food Name</FormLabel>
                <Input
                  id="name"
                  type="name"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter the name of food item here"
                  onBlur={handleImageSearch}
                />
              </FormControl>

              <FormControl my={3}>
                <FormLabel>Description</FormLabel>
                <Input
                  id="description"
                  type="description"
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter description of food here."
                />
                <FormHelperText>
                  Do not use quotation marks in the descriptions as it creates issues when the data
                  is scripted into a CSV file. (Ex: 12" should be 12 inch instead.)
                </FormHelperText>
              </FormControl>

              <FormControl my={3}>
                <FormLabel>Cost</FormLabel>
                <Input
                  id="cost"
                  type="cost"
                  onChange={(e) => setCost(e.target.value)}
                  placeholder="Enter cost of food here."
                />
                <FormHelperText>
                  Enter full dollar value without dollar sign. Ex: If $5.00 enter "5.00". If $5.50
                  enter "5.50"
                </FormHelperText>
              </FormControl>

              <FormControl my={3}>
                <FormLabel>Category</FormLabel>
                <Select
                  placeholder="Select food category"
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="BREAD">🥐 BREAD</option>
                  <option value="DAIRY">🥛 DAIRY</option>
                  <option value="DRINKS">🥤 DRINKS</option>
                  <option value="FRUITS">🍎 FRUITS</option>
                  <option value="MEAT">🥩 MEAT</option>
                  <option value="MISCELLANEOUS">🍕 MISCELLANEOUS</option>
                  <option value="VEGETABLES">🥦 VEGETABLES</option>

                  {/* <option value="Meat">Meat</option>
                  <option value="Fruits & Vegetables">Fruits & Vegetables</option>
                  <option value="Miscellaneous">Miscellaneous</option> */}
                </Select>
              </FormControl>
            </Flex>
            <Flex flexWrap="wrap" w="100%" justifyContent="space-evenly" pb={5}>
              {searchedImages
                ? searchedImages.map((image, index) => (
                    <ImageResult
                      url={image.src.large}
                      selectedImage={selectedImage}
                      setSelectedImage={setSelectedImage}
                      index={index}
                      setImageUrl={setImageUrl}
                    />
                  ))
                : null}
              {noImgResults ? (
                <Text letterSpacing={3} mt={5} fontSize="20px">
                  NO IMAGE RESULTS
                </Text>
              ) : null}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex justify="flex-end" w="100%">
              {success ? (
                <Alert status="success">
                  <AlertIcon />
                  <AlertTitle>Success!</AlertTitle>
                </Alert>
              ) : null}
              {error ? (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle>Error!</AlertTitle>
                </Alert>
              ) : null}
            </Flex>
            <Button
              w="200px"
              ml={2}
              colorScheme="blue"
              isDisabled={loading}
              isLoading={loading}
              onClick={addNewMenuItem}
            >
              Add New Item
            </Button>
            {/* <Button onClick={handleImageSearch}>Search Image</Button> */}
            {/* <Button onClick={() => console.log(searchedImages)}>LOG Image</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
