import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Button,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Box,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { MdOutlineAddBox } from "react-icons/md";
import ItemCard from "./ItemCard";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const TableRow = ({ menuItem, addNewCartItem }) => {
  const [quantity, setQuantity] = useState(0);
  const buttonSize = useBreakpointValue(["xs", "md"]);
  const inputSize = useBreakpointValue(["xs", "sm"]);
  const rowHeight = useBreakpointValue(["50px", null]);

  return (
    <Tr _hover={{ backgroundColor: "gray.100", fontWeight: 700 }} h={rowHeight}>
      <Td fontSize={{ base: 10, md: 16 }} w="30%">
        {menuItem.name.toUpperCase()}
      </Td>
      <Td fontSize={{ base: 10, md: 16 }} w="30%">
        {menuItem.description ? menuItem.description.toUpperCase() : null}
      </Td>
      <Td fontSize={{ base: 10, md: 16 }} w="15%" textAlign={{ base: "center", med: "right" }}>
        {formatter.format(menuItem.cost)}
      </Td>
      <Td fontSize={{ base: 10, md: 16 }} w="15%">
        <NumberInput
          defaultValue={0}
          min={0}
          onChange={(e) => setQuantity(e)}
          zIndex={0}
          size={inputSize}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Td>
      <Td fontSize={{ base: 10, md: 12 }} w="10%" textAlign="center">
        <Button
          isDisabled={quantity < 1 ? true : false}
          size={buttonSize}
          colorScheme="blue"
          zIndex={0}
          onClick={() => addNewCartItem(menuItem, quantity)}
          // size={["sm", "md", "md", "md"]}
        >
          Add
        </Button>
      </Td>
    </Tr>
  );
};

export default function MenuTable({ menuData, addNewCartItem }) {
  const tableSize = useBreakpointValue(["xs", "md"]);
  const topSticky = useBreakpointValue([177, 250]);
  const headerTextAlign = useBreakpointValue(["center", "left"]);

  return (
    <Flex flexWrap="wrap" justifySelf="center" justify="flex-start" margin="auto" w="100%" p={1}>
      {menuData
        ? menuData.map((item, index) => (
            <ItemCard menuItem={item} addNewCartItem={addNewCartItem} />
          ))
        : null}
      {/* <Table variant="simple" m={0} size={tableSize}>
      <Thead m={0}>
        <Tr backgroundColor="gray.200" zIndex={1} position="sticky" top={topSticky} h="50px">
          <Th fontSize={{ base: 8, md: 12 }} textAlign={headerTextAlign}>
            Item Name
          </Th>
          <Th fontSize={{ base: 8, md: 12 }} textAlign={headerTextAlign}>
            Description
          </Th>
          <Th fontSize={{ base: 8, md: 12 }} textAlign={{ base: "center", med: "right" }}>
            Cost
          </Th>
          <Th fontSize={{ base: 8, md: 12 }} textAlign="center">
            Quantity
          </Th>
          <Th fontSize={{ base: 8, md: 12 }} textAlign="center">
            Cart
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {menuData
          ? menuData.map((item, index) => (
              <TableRow
                key={`${item.name}-${index}`}
                menuItem={item}
                addNewCartItem={addNewCartItem}
              />
            ))
          : null}
        
      </Tbody>
    </Table> */}
    </Flex>
  );
}
