import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import GoogleButton from "react-google-button";
import {
  auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithRedirect,
} from "../../firebase";

// import "./Login.css";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

export default function Login({ setCurrentUser, loginComponent }) {
  const [signUp, setSignUp] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const containerSize = useBreakpointValue(["sm", "xl"]);

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    setLoading(true);

    try {
      const result = await signInWithRedirect(auth, provider);
      // console.log(result);
      setCurrentUser(result.user);
      // console.log("Logged in with", result.user);
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  const handleSigninWithEmailAndPassword = async () => {
    if (!email) {
      alert("Enter a valid email address.");
      return;
    }
    if (!password) {
      alert("Enter a valid password.");
      return;
    }
    if (email && password) {
      setLoading(true);

      try {
        const result = await signInWithEmailAndPassword(auth, email, password);
        setCurrentUser(result.user);
        // console.log("Logged in with", result.user);
        setLoading(false);
      } catch (error) {
        alert(error.message);
        setLoading(false);
      }
    }
  };

  const handleSignUpwithEmailAndPassword = async () => {
    if (!email) {
      alert("Enter a valid email address.");
      return;
    }
    if (!password) {
      alert("Enter a valid password.");
      return;
    }
    if (!confirmPassword) {
      alert("Enter a confirm your password.");
      return;
    }
    if (password !== confirmPassword) {
      alert("Enter password and confirmation do not match.");
      return;
    }
    if (email && password && confirmPassword && password === confirmPassword) {
      setLoading(true);

      try {
        const result = await createUserWithEmailAndPassword(auth, email, password);
        // console.log("Logged in with", result.user);
        setCurrentUser(result.user);
        setLoading(false);
      } catch (error) {
        alert(error.message);
        setLoading(false);
      }
    }
  };

  const handleShowPassword = () => {
    if (showPassword) setShowPassword(false);
    if (!showPassword) setShowPassword(true);
  };
  const handleShowConfirmPassword = () => {
    if (showConfirmPassword) setShowConfirmPassword(false);
    if (!showConfirmPassword) setShowConfirmPassword(true);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        // console.log("currentUser: ", user);
      }
    });
    return unsubscribe();
  }, []);

  return (
    <>
      {loginComponent === "text" ? (
        <Text
          letterSpacing={3}
          fontSize={{ base: 14, md: 18 }}
          _hover={{ fontWeight: 600 }}
          cursor="pointer"
          onClick={onOpen}
        >
          LOGIN
        </Text>
      ) : (
        <Link
          fontSize={[12, 24]}
          letterSpacing="4px"
          mt="40px"
          onClick={onOpen}
          textDecoration="underline"
        >
          PLEASE LOGIN TO SUBMIT AN ORDER.
        </Link>
      )}

      <Modal
        className="login"
        justifyContent="center"
        alignItems="center"
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={containerSize}
        //   w="100vw"
        //   h="100vh"
        //   backgroundColor="red"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" fontSize="2xl">
            PK CDCR (LAC) ACCOUNT
          </ModalHeader>
          <ModalBody>
            {signUp ? (
              <Flex
                className="login__container"
                flexDir="column"
                w="100%"
                alignSelf="center"
                justifySelf="center"
                mb="10%"
              >
                <FormControl id="email">
                  <FormLabel>Email address</FormLabel>
                  <Input type="email" onChange={(e) => setEmail(e.target.value)} />
                </FormControl>
                <FormControl id="password">
                  <FormLabel>Password</FormLabel>
                  <Flex>
                    <Input
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <IconButton
                      onClick={handleShowPassword}
                      icon={showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                    />
                  </Flex>
                </FormControl>
                <FormControl id="confirm-password">
                  <FormLabel>Confirm Password</FormLabel>
                  <Flex>
                    <Input
                      type={showConfirmPassword ? "text" : "password"}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <IconButton
                      onClick={handleShowConfirmPassword}
                      icon={showConfirmPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                    />
                  </Flex>
                </FormControl>
                <Button
                  mt={8}
                  colorScheme="blue"
                  onClick={handleSignUpwithEmailAndPassword}
                  isLoading={loading}
                >
                  Create Account
                </Button>
                <Text textAlign="center" fontSize={[10, 12]} mt={1}>
                  YOUR EMAIL WILL NEVER BE SHARED WITH ANY OUTSIDE SOURCES AND WILL ONLY BE USED FOR
                  THE SOLE PURPOSE OF AUTHENTICATION.
                </Text>
                <Link onClick={() => setSignUp(false)} mt={4} w="100%" textAlign="center">
                  Login
                </Link>
              </Flex>
            ) : (
              <Flex
                className="login__container"
                flexDir="column"
                w="100%"
                alignSelf="center"
                justifySelf="center"
                mb="10%"
              >
                <FormControl id="email">
                  <FormLabel>Email address</FormLabel>
                  <Input type="email" onChange={(e) => setEmail(e.target.value)} />
                </FormControl>
                <FormControl id="password">
                  <FormLabel>Password</FormLabel>
                  <Input type="password" onChange={(e) => setPassword(e.target.value)} />
                </FormControl>

                <Button
                  mt={8}
                  colorScheme="blue"
                  onClick={handleSigninWithEmailAndPassword}
                  isLoading={loading}
                >
                  Login
                </Button>
                {/* <Flex w="100%" justifyContent="center" alignItems="center" mt={3}>
                  <GoogleButton onClick={handleGoogleSignIn} />
                </Flex> */}
                <Link onClick={() => setSignUp(true)} mt={4} w="100%" textAlign="center">
                  Create Account
                </Link>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
