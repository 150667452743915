import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Button,
  Text,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Select,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { db } from "../../../../firebase";
import { collection, deleteDoc, doc, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import moment from "moment";

import { MdOutlineAddBox } from "react-icons/md";
import DeleteConfirmation from "../../../modals/DeleteConfirmation";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const TableRow = ({ order }) => {
  return (
    <Tr _hover={{ backgroundColor: "gray.100" }}>
      <Td w="35%">{order.name.toUpperCase()}</Td>
      <Td w="35%">{order.description ? order.description.toUpperCase() : null}</Td>
      <Td w="10%" isNumeric>
        {order.quantity}
      </Td>
      <Td w="10%" isNumeric>
        {formatter.format(order.cost)}
      </Td>
      <Td w="10%" isNumeric>
        {formatter.format(Number(order.cost) * Number(order.quantity))}
      </Td>
    </Tr>
  );
};

export default function OrderHistoryTable({ order, getAllOrders }) {
  const [status, setStatus] = useState(order.status);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [pastOrder, setPastOrder] = useState(
    moment(order.orderInfo.dateOfVisit).isSameOrBefore(moment()) ? true : false
  );

  const updateStatus = async (newStatus) => {
    console.log(order);
    setLoading(true);

    const data = {
      status: newStatus,
    };
    const orderRef = doc(db, "orders", order.orderId);

    console.log("data:", data);
    console.log("orderRef:", orderRef);

    try {
      const result = await updateDoc(orderRef, data, { merge: true });
      console.log(result);
      setLoading(false);
      getAllOrders();
    } catch (error) {}
  };

  const deleteOrder = async () => {
    setDeleteLoading(true);

    const deleteRef = doc(db, "orders", order.orderId);

    try {
      const result = await deleteDoc(deleteRef);
      setDeleteLoading(false);
      getAllOrders();
    } catch (error) {
      alert(error.message);
    }
  };

  const logMe = () => {
    console.log(order);
  };

  return (
    <AccordionItem px={2}>
      <Flex borderWidth={1} p={5} flexDir="column" my={5} boxShadow="lg">
        <AccordionButton>
          {/* <Button onClick={logMe}>logMe</Button> */}
          <Flex alignItems="center" w="100%">
            <Table variant="simple" m={0}>
              <Thead m={0}>
                <Tr backgroundColor="gray.200">
                  <Th textAlign="center" w="13%">
                    Date of Order
                  </Th>
                  <Th textAlign="center" w="13%">
                    Date of Visit
                  </Th>
                  <Th textAlign="center" w="13%">
                    Status
                  </Th>
                  <Th textAlign="center" w="13%">
                    Visitor Name
                  </Th>
                  <Th textAlign="center" w="13%">
                    Inmate Name
                  </Th>
                  <Th textAlign="center" w="13%">
                    Inmate CDCR #
                  </Th>
                  <Th textAlign="center" w="22%">
                    Notes
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr backgroundColor="gray.100">
                  <Td textAlign="center" w="13%">
                    {order.orderDate}
                  </Td>
                  <Td
                    textAlign="center"
                    w="13%"
                    color={pastOrder && status === "pending" ? "red.400" : null}
                  >
                    {order.orderInfo.dateOfVisit}
                  </Td>
                  <Td
                    textAlign="center"
                    w="13%"
                    color={pastOrder && status === "pending" ? "red.400" : null}
                  >
                    {order.status}
                  </Td>
                  <Td textAlign="center" w="13%">
                    {order.orderInfo.visitorName}
                  </Td>
                  <Td textAlign="center" w="13%">
                    {order.orderInfo.inmateName}
                  </Td>
                  <Td textAlign="center" w="13%">
                    {order.orderInfo.inmateCdcr}
                  </Td>
                  <Td textAlign="center" w="22%">
                    {order.orderInfo.notes}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            <AccordionIcon ml={3} />
          </Flex>
        </AccordionButton>

        <AccordionPanel>
          <Table variant="simple" m={0}>
            <Thead m={0}>
              <Tr backgroundColor="gray.200">
                <Th>Item Name</Th>
                <Th>Description</Th>
                <Th textAlign="right">Quantity</Th>
                <Th textAlign="right">Cost</Th>
                <Th textAlign="right">Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {order
                ? order.order.map((item, index) => (
                    <TableRow key={`${item.name}-${index}`} order={item} />
                  ))
                : null}
              <Tr backgroundColor="gray.200">
                <Td>
                  <Flex>
                    {order.status === "pending" ? (
                      <Flex>
                        <Tooltip hasArrow placement="top" label="Set status of order to complete">
                          <Button
                            colorScheme="blue"
                            onClick={() => updateStatus("complete")}
                            isLoading={loading}
                          >
                            Complete Order
                          </Button>
                        </Tooltip>
                        <Tooltip
                          hasArrow
                          placement="top"
                          label="Set status of order to Cancelled/No Show"
                        >
                          <Button
                            ml={3}
                            colorScheme="yellow"
                            onClick={() => updateStatus("cancelled/no show")}
                            isLoading={loading}
                          >
                            Cancelled / No Show
                          </Button>
                        </Tooltip>
                      </Flex>
                    ) : (
                      <Tooltip hasArrow placement="top" label="Set status of order to pending">
                        <Button
                          colorScheme="blue"
                          onClick={() => updateStatus("pending")}
                          isLoading={loading}
                        >
                          Set Pending
                        </Button>
                      </Tooltip>
                    )}
                    <Box boxSize={3} />

                    <DeleteConfirmation
                      event={deleteOrder}
                      loading={deleteLoading}
                      question="Would you really like to delete this order?"
                      label="Delete Order"
                    />
                  </Flex>
                </Td>
                <Td></Td>
                <Td></Td>
                <Td textAlign="right" fontWeight={700}>
                  TOTAL
                </Td>
                <Td isNumeric>{order.totalCost}</Td>
              </Tr>
            </Tbody>
          </Table>
        </AccordionPanel>
      </Flex>
    </AccordionItem>
  );
}
