import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { AiOutlineShoppingCart, AiOutlineMenu } from "react-icons/ai";
import { FaDoorOpen } from "react-icons/fa";
import { auth, signOut } from "../../firebase";

import "./ToolTip.css";
import "./WaveEmoji.css";
import Login from "../pages/Login";
import { ColorModeButton } from "./ColorMode";
import NewTag from "./NewTag";

const CartNumberIcon = ({ cartNumber, setShowPage }) => {
  const iconSize = useBreakpointValue([5, 6]);
  const iconFontSize = useBreakpointValue([8, 11]);

  return (
    <Flex
      onClick={() => setShowPage("cart")}
      w={iconSize}
      h={iconSize}
      // backgroundColor="white"
      // right={0}
      justifyContent="center"
      alignItems="flex-start"
      ml={-3}
      zIndex={1}
      // position="absolute"
    >
      {cartNumber > 0 ? (
        <Flex
          boxSize={cartNumber > 9 ? 6 : 5}
          backgroundColor="red.400"
          justify="center"
          alignItems="center"
          borderRadius={50}
          cursor="pointer"
        >
          <Text color="white" fontWeight={700} fontSize={iconFontSize}>
            {cartNumber}
          </Text>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default function Navbar({
  cartItems,
  setShowPage,
  showPage,
  setShowMenu,
  setCurrentUser,
  admin,
  currentUser,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const buttonSize = useBreakpointValue(["md", "lg"]);
  const drawerSize = useBreakpointValue(["xs", "md"]);

  const handleMenuClick = (menuPage) => {
    if (showPage !== "menu") {
      setShowPage("menu");
    }
    setShowMenu(menuPage);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Flex
      justify="flex-end"
      w={{ base: "110px", md: "150px" }}
      pr={{ base: 0, md: 5 }}
      alignItems="center"
    >
      <Flex>
        <IconButton
          icon={<AiOutlineMenu />}
          ref={btnRef}
          colorScheme="blue"
          onClick={onOpen}
          mr={{ base: 1, md: 2 }}
          size={buttonSize}
        />

        <IconButton
          icon={<AiOutlineShoppingCart />}
          onClick={() => setShowPage("cart")}
          size={buttonSize}
        />

        <CartNumberIcon setShowPage={setShowPage} cartNumber={cartItems ? cartItems.length : 0} />
      </Flex>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size={drawerSize}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {currentUser ? (
              <Flex w="100%" alignItems="center">
                <Flex flexDir="column">
                  <Text letterSpacing={3} fontSize={{ base: 10, md: 14 }} fontStyle="italic">
                    WELCOME BACK,
                  </Text>
                  <Text letterSpacing={3} fontSize={{ base: 10, md: 14 }} fontStyle="italic">
                    {currentUser?.email.toLowerCase()}
                  </Text>
                </Flex>
                <Text className="wave" ml={1} fontSize="22px">
                  👋
                </Text>
              </Flex>
            ) : null}
          </DrawerHeader>

          <DrawerBody>
            <Flex flexDir="column">
              <Text
                letterSpacing={3}
                fontSize={{ base: 20, md: 28 }}
                fontWeight={500}
                onClick={() => handleMenuClick("ALL")}
                _hover={{ fontWeight: 700 }}
                cursor="pointer"
                color={showPage === "menu" ? "blue.400" : null}
              >
                MENU
              </Text>
            </Flex>
            <Flex flexDir="column" my={5}>
              <Text
                letterSpacing={3}
                fontSize={{ base: 20, md: 28 }}
                fontWeight={500}
                _hover={{ fontWeight: 700 }}
                cursor="pointer"
                onClick={() => setShowPage("cart")}
                color={showPage === "cart" ? "blue.400" : null}
              >
                CART
              </Text>
            </Flex>
            {/* {currentUser && !admin ? (
              <Flex flexDir="column" my={5}>
                <Text
                  letterSpacing={3}
                  fontSize={{ base: 20, md: 28 }}
                  fontWeight={500}
                  // _hover={{ fontWeight: 700 }}
                  // cursor="pointer"
                >
                  ACCOUNT
                </Text>
                <Flex alignItems="center">
                  <Text
                    letterSpacing={3}
                    fontSize={{ base: 14, md: 18 }}
                    _hover={{ fontWeight: 600 }}
                    cursor="pointer"
                    onClick={() => setShowPage("myOrders")}
                    color={showPage === "myOrders" ? "blue.400" : null}
                  >
                    MY ORDERS
                  </Text>
                  <NewTag />
                </Flex>
                <Flex alignItems="center">
                  <Text
                    letterSpacing={3}
                    fontSize={{ base: 14, md: 18 }}
                    _hover={{ fontWeight: 600 }}
                    cursor="pointer"
                    onClick={() => setShowPage("myInfo")}
                    color={showPage === "myInfo" ? "blue.400" : null}
                  >
                    MY INFO
                  </Text>
                  
                </Flex>
              </Flex>
            ) : null} */}

            {admin ? (
              <Flex flexDir="column" my={5}>
                <Text letterSpacing={3} fontSize={{ base: 20, md: 28 }} fontWeight={500}>
                  ADMIN
                </Text>

                <>
                  <Text
                    letterSpacing={3}
                    fontSize={{ base: 14, md: 18 }}
                    _hover={{ fontWeight: 600 }}
                    cursor="pointer"
                    onClick={() => setShowPage("orders")}
                    color={showPage === "orders" ? "blue.400" : null}
                  >
                    ORDERS
                  </Text>

                  <Text
                    letterSpacing={3}
                    fontSize={{ base: 14, md: 18 }}
                    _hover={{ fontWeight: 600 }}
                    cursor="pointer"
                    onClick={() => setShowPage("products")}
                    color={showPage === "products" ? "blue.400" : null}
                  >
                    PRODUCTS
                  </Text>
                </>
              </Flex>
            ) : null}

            {/* {!admin ? (
              <Flex flexDir="column" my={5}>
                <Text letterSpacing={3} fontSize={{ base: 20, md: 28 }} fontWeight={500}>
                  ACCOUNT
                </Text>
                {currentUser ? (
                  <Text
                    letterSpacing={3}
                    fontSize={{ base: 14, md: 18 }}
                    _hover={{ fontWeight: 600 }}
                    cursor="pointer"
                    onClick={handleLogout}
                  >
                    LOGOUT
                  </Text>
                ) : (
                  <Login setCurrentUser={setCurrentUser} loginComponent="text" />
                )}
              </Flex>
            ) : null} */}
          </DrawerBody>

          <DrawerFooter>
            {currentUser ? (
              <Flex
                w="100%"
                alignItems="center"
                justify="flex-start"
                _hover={{ fontWeight: 600, color: "red.500" }}
              >
                <Text
                  letterSpacing={3}
                  fontSize={{ base: 10, md: 14 }}
                  cursor="pointer"
                  onClick={handleLogout}
                >
                  LOGOUT
                </Text>
                <Icon boxSize={5} as={FaDoorOpen} />
              </Flex>
            ) : (
              <Flex
                w="100%"
                alignItems="center"
                justify="flex-start"
                _hover={{ fontWeight: 600, color: "green.500" }}
              >
                <Login setCurrentUser={setCurrentUser} loginComponent="text" />
                <Icon boxSize={5} as={FaDoorOpen} />
              </Flex>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
