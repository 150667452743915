import {
  Box,
  Button,
  ChakraProvider,
  Flex,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { useState } from 'react'

import { v4 as uuid } from 'uuid'

import logo from './logo.svg'
import './App.css'

import Header from './app/components/Header'
import Menu from './app/pages/Menu'
import Navbar from './app/components/Navbar'
import Order from './app/pages/Order'
import Products from './app/pages/Products'
import OrderHistory from './app/pages/OrderHistory'
// import { AuthContextProvider, UserAuth } from "./app/context/AuthContext";
import Login from './app/pages/Login'
import { useEffect } from 'react'
import LoginWarning from './app/modals/LoginWarning'
import Footer from './app/components/Footer'
import { ColorModeButton } from './app/components/ColorMode'
import MyOrders from './app/pages/MyOrders'
import MyInfo from './app/components/my-accounts/MyInfo'

function App () {
  const [cartItems, setCartItems] = useState([])
  const [showPage, setShowPage] = useState('menu')
  const [showMenu, setShowMenu] = useState('ALL')
  const [sessionId, setSessionId] = useState(uuid())
  const [currentUser, setCurrentUser] = useState(false)
  const [admin, setAdmin] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  // const { user, logOut } = UserAuth();

  const addNewCartItem = (item, quantity) => {
    const existingCartItem = cartItems.find(
      cartItem => cartItem.name === item.name
    )

    if (existingCartItem) {
      const updatedCartItems = cartItems.map(cartItem => {
        if (cartItem.name === item.name) {
          return {
            ...cartItem,
            quantity: cartItem.quantity + Number(quantity)
          }
        }
        return cartItem
      })
      setCartItems(updatedCartItems)
    } else {
      const cartItem = {
        name: item.name,
        cost: item.cost,
        description: item.description,
        quantity: Number(quantity),
        category: item.category,
        url: item.url
      }
      setCartItems([...cartItems, cartItem])
    }
  }

  useEffect(() => {
    if (
      currentUser?.uid === 'ecMlJpPyTGMNVk1v1tyeMNoJ1l62' ||
      currentUser?.uid === 'JqgFTqc7tGTuDKgoN7dZmEPtfqs2' ||
      currentUser?.uid === 'vFaomsJ5sGhLuyNi1Vl40geIXKJ2'
    ) {
      setAdmin(true)
    } else setAdmin(false)
  }, [currentUser])

  const showWarningModal = async () => {
    setTimeout(() => {
      onOpen()
    }, 1500)
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    // console.log("currentUser:", currentUser);
    if (!currentUser) {
      showWarningModal()
    }
  }, [currentUser])

  useEffect(() => {
    if (showPage) {
      scrollToTop()
    }
  }, [showPage])

  return (
    <ChakraProvider>
      <Flex
        m='auto'
        alignSelf='center'
        justifySelf='center'
        w={{ base: '100vw', md: '1315px' }}
        className='App'
        flexDir='column'
        p={3}
        // backgroundColor="gray.200"
      >
        {/* <Button onClick={addNewCartItem}>addNewCartItem</Button> */}
        <Flex
          position='sticky'
          m={0}
          top={0}
          zIndex={1}
          justify='space-between'
          alignItems='center'
          backgroundColor='white'
        >
          {/* <Box w={{ base: "110px", md: "150px" }} h="50px" pl={{ base: 0, md: 5 }}></Box> */}
          <Header setShowPage={setShowPage} />
          <Navbar
            cartItems={cartItems}
            setShowPage={setShowPage}
            showPage={showPage}
            setShowMenu={setShowMenu}
            setCurrentUser={setCurrentUser}
            admin={admin}
            currentUser={currentUser}
          />
        </Flex>
        {/* <Button onClick={() => console.log(sessionId)}>SessionID</Button> */}
        {showPage === 'menu' ? (
          <Menu
            addNewCartItem={addNewCartItem}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
          />
        ) : null}
        {showPage === 'cart' ? (
          <Order
            cartItems={cartItems}
            setCartItems={setCartItems}
            sessionId={sessionId}
            setShowPage={setShowPage}
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
          />
        ) : null}
        {showPage === 'myOrders' ? <MyOrders myUid={currentUser.uid} /> : null}
        {showPage === 'myInfo' ? <MyInfo myUid={currentUser.uid} /> : null}
        {showPage === 'products' ? (
          <Products
            addNewCartItem={addNewCartItem}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
          />
        ) : null}
        {showPage === 'orders' ? <OrderHistory /> : null}
        {/* {!currentUser ? (
        <LoginWarning currentUser={currentUser} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      ) : null} */}
        <Footer />
      </Flex>
    </ChakraProvider>
  )
}

export default App
