import { Box, Button, Divider, Flex, Spinner, Text, useBreakpointValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { firebase, db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import MenuTable from "../components/menu/MenuTable";
import MenuNavBar from "../components/menu/MenuNavBar";
import ProductsTable from "../components/admin/products/ProductsTable";
import AdminMenuNavBar from "../components/menu/AdminMenuNavBar";
import ProductCards from "../components/admin/products/ProductCard";

export default function Products({ addNewCartItem, showMenu, setShowMenu }) {
  const [menuData, setMenuData] = useState();
  const [filteredMenuData, setFilteredMenuData] = useState();
  const [breadItems, setBreadItems] = useState();
  const [dairyItems, setDairyItems] = useState();
  const [drinkItems, setDrinkItems] = useState();
  const [fruitItems, setFruitItems] = useState();
  const [meatItems, setMeatItems] = useState();
  const [veggieItems, setVeggieItems] = useState();
  const [miscItems, setMiscItems] = useState();
  const [searchField, setSearchField] = useState("");
  const [loading, setLoading] = useState(false);
  const topSticky = useBreakpointValue([50, 82]);

  const getAllMenuItems = async () => {
    setLoading(true);
    setMenuData(null);
    const data = [];
    try {
      const querySnapshot = await getDocs(collection(db, "menu"));
      querySnapshot.forEach((doc) => {
        const menuItem = {
          id: doc.id,
          category: doc.data().category,
          cost: doc.data().cost,
          created: doc.data().created,
          description: doc.data().description,
          name: doc.data().name,
          url: doc.data().url,
        };
        // console.log(doc.id, doc.data());
        data.push(menuItem);
      });
      setLoading(false);
      setMenuData(data);
    } catch (error) {
      alert(error.message);
    }
  };

  const sortByName = (a, b) => {
    if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
    return 0;
  };

  const sortIntoItemCategories = () => {
    const bread = [];
    const dairy = [];
    const drinks = [];
    const fruit = [];
    const meats = [];
    const veggies = [];
    const misc = [];
    if (menuData) {
      menuData.forEach((item) => {
        if (item.category === "BREAD") bread.push(item);
        if (item.category === "DAIRY") dairy.push(item);
        if (item.category === "DRINKS") drinks.push(item);
        if (item.category === "FRUITS") fruit.push(item);
        if (item.category === "MEAT") meats.push(item);
        if (item.category === "MISCELLANEOUS") misc.push(item);
        if (item.category === "VEGETABLES") veggies.push(item);
      });
    }
    setBreadItems(bread.sort(sortByName));
    setDairyItems(dairy.sort(sortByName));
    setDrinkItems(drinks.sort(sortByName));
    setFruitItems(fruit.sort(sortByName));
    setMeatItems(meats.sort(sortByName));
    setVeggieItems(veggies.sort(sortByName));
    setMiscItems(misc.sort(sortByName));
  };

  const handleFilterSearch = (searchText) => {
    const filteredItems = menuData.filter((item) => {
      return item.name.toLowerCase().includes(searchText.toLowerCase());
    });

    setFilteredMenuData(filteredItems);
  };

  const logMe = () => {
    console.log(menuData);
    // console.log("meatItems:", meatItems);
    // console.log("fruitVeggieItems:", fruitVeggieItems);
    // console.log("miscItems:", miscItems);
  };

  useEffect(() => {
    getAllMenuItems();
  }, []);

  useEffect(() => {
    if (menuData) {
      sortIntoItemCategories();
    }
  }, [menuData]);

  return (
    <Box>
      <Flex flexDir="column" backgroundColor="white">
        {/* <Button onClick={logMe}>logMe</Button> */}
        {/* <Button onClick={getAllMenuItems}>GET MENU ITEMS</Button> */}
        <Flex
          flexDir="column"
          position="sticky"
          top={topSticky}
          zIndex={1}
          justify="flex-start"
          backgroundColor="white"
        >
          <Text
            letterSpacing={3}
            fontSize={{ sm: 24, md: 28, lg: 32 }}
            w="100%"
            backgroundColor="blackAlpha.200"
            p={1}
          >
            PRODUCTS
          </Text>
          <AdminMenuNavBar
            setShowMenu={setShowMenu}
            showMenu={showMenu}
            handleFilterSearch={handleFilterSearch}
            setSearchField={setSearchField}
            menuData={menuData ? true : false}
            getAllMenuItems={getAllMenuItems}
          />
        </Flex>
        {loading ? (
          <Flex justifyContent="center" alignItems="center" mt="10%">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        ) : null}
        {filteredMenuData && showMenu === "ALL" ? (
          <ProductCards
            menuData={filteredMenuData.sort(sortByName)}
            addNewCartItem={addNewCartItem}
            getAllMenuItems={getAllMenuItems}
          />
        ) : null}
        {!filteredMenuData && menuData && showMenu === "ALL" ? (
          <ProductCards
            menuData={menuData.sort(sortByName)}
            addNewCartItem={addNewCartItem}
            getAllMenuItems={getAllMenuItems}
          />
        ) : null}
        {(filteredMenuData || menuData) && showMenu === "BREAD" ? (
          <ProductCards
            menuData={breadItems.sort(sortByName)}
            addNewCartItem={addNewCartItem}
            getAllMenuItems={getAllMenuItems}
          />
        ) : null}
        {(filteredMenuData || menuData) && showMenu === "DAIRY" ? (
          <ProductCards
            menuData={dairyItems.sort(sortByName)}
            addNewCartItem={addNewCartItem}
            getAllMenuItems={getAllMenuItems}
          />
        ) : null}
        {(filteredMenuData || menuData) && showMenu === "DRINKS" ? (
          <ProductCards
            menuData={drinkItems.sort(sortByName)}
            addNewCartItem={addNewCartItem}
            getAllMenuItems={getAllMenuItems}
          />
        ) : null}
        {(filteredMenuData || menuData) && showMenu === "FRUITS" ? (
          <ProductCards
            menuData={fruitItems.sort(sortByName)}
            addNewCartItem={addNewCartItem}
            getAllMenuItems={getAllMenuItems}
          />
        ) : null}
        {(filteredMenuData || menuData) && showMenu === "MEAT" ? (
          <ProductCards
            menuData={meatItems.sort(sortByName)}
            addNewCartItem={addNewCartItem}
            getAllMenuItems={getAllMenuItems}
          />
        ) : null}
        {(filteredMenuData || menuData) && showMenu === "MISCELLANEOUS" ? (
          <ProductCards
            menuData={miscItems.sort(sortByName)}
            addNewCartItem={addNewCartItem}
            getAllMenuItems={getAllMenuItems}
          />
        ) : null}
        {(filteredMenuData || menuData) && showMenu === "VEGETABLES" ? (
          <ProductCards
            menuData={veggieItems.sort(sortByName)}
            addNewCartItem={addNewCartItem}
            getAllMenuItems={getAllMenuItems}
          />
        ) : null}
        {/* {menuData && meatItems && showMenu === "meat" ? (
          <ProductsTable
            menuData={meatItems}
            addNewCartItem={addNewCartItem}
            getAllMenuItems={getAllMenuItems}
          />
        ) : null}
        {menuData && veggieItems && showMenu === "fruits-veggies" ? (
          <ProductsTable
            menuData={veggieItems}
            addNewCartItem={addNewCartItem}
            getAllMenuItems={getAllMenuItems}
          />
        ) : null}
        {menuData && miscItems && showMenu === "misc" ? (
          <ProductsTable
            menuData={miscItems}
            addNewCartItem={addNewCartItem}
            getAllMenuItems={getAllMenuItems}
          />
        ) : null} */}
      </Flex>
    </Box>
  );
}
