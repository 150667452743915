import {
  IconButton,
  Input,
  Box,
  Tooltip,
  Flex,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { db } from "../../../../firebase";
import { collection, doc, setDoc, deleteDoc } from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { searchForPhotos } from "../../../api/Pexels";

import "./ProductCard.css";
import { ImageResult } from "./ImageResult";

import { AiFillSave } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import DeleteConfirmation from "../../../modals/DeleteConfirmation";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const Card = ({ menuItem, getAllMenuItems }) => {
  const formatCost = () => {
    let newCost;
    if (menuItem.cost.includes(".")) {
      const costArray = menuItem.cost.split(".");

      if (costArray[1].length > 1) {
        newCost = menuItem.cost;
      } else {
        newCost = `$${menuItem.cost}.0`;
      }
    } else {
      newCost = `${menuItem.cost}.00`;
    }
    return newCost;
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [name, setName] = useState(menuItem.name.toUpperCase());
  const [description, setDescription] = useState(
    menuItem.description ? menuItem.description.toUpperCase() : ""
  );
  const [cost, setCost] = useState(formatCost());
  const [category, setCategory] = useState(menuItem.category.toUpperCase());
  const [imageUrl, setImageUrl] = useState(menuItem.url ? menuItem.url : null);
  const [selectedImage, setSelectedImage] = useState();
  const [searchedImages, setSearchedImages] = useState();
  const [noImgResults, setNoImgResults] = useState(false);

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const updateItem = async () => {
    setLoading(true);

    const data = {
      name: name.toUpperCase(),
      description: description ? description.toUpperCase() : null,
      cost,
      category,
      url: imageUrl,
    };

    console.log("updateItem data:", data);

    const productRef = doc(db, "menu", menuItem.id);
    try {
      const result = await setDoc(productRef, data, { merge: true });
      // console.log("updateItem:", result);
      setLoading(false);
      getAllMenuItems();
      onClose();
    } catch (error) {
      alert(error.message);
    }
  };

  const deleteItem = async () => {
    setDeleteLoading(true);

    const deleteRef = doc(db, "menu", menuItem.id);

    try {
      const result = await deleteDoc(deleteRef);
      // console.log("deleteItem result:", result);
      setDeleteLoading(false);
      getAllMenuItems();
      onClose();
    } catch (error) {
      alert(error.message);
    }
  };

  const handleImageSearch = async () => {
    if (name) {
      try {
        const result = await searchForPhotos(name);
        if (result.photos.length > 0) {
          setNoImgResults(false);
          setSearchedImages(result.photos);
        } else {
          setSearchedImages();
          setNoImgResults(true);
        }
      } catch (error) {
        alert(error.message);
      }
    } else {
      alert("add a food name");
    }
  };

  return (
    <>
      <Flex
        m={2}
        flexDir={{ base: "row", md: "column" }}
        w={{ base: "100%", md: "240px" }}
        h={{ base: "180px", md: "400px" }}
        borderWidth={1}
        borderColor="gray.400"
        style={{ boxShadow: "0 0px 8px 1px RGBA(0, 0, 0, 0.24)" }}
        _hover={{ boxShadow: "0 0px 10px 1px RGBA(0, 0, 0, 0.34)" }}
        className="card"
        p={4}
        cursor="pointer"
        borderRadius={5}
        alignItems={{ base: "center", md: "flex-start" }}
        onClick={onOpen}
      >
        <Box
          w={{ base: "50%", md: "100%" }}
          h={{ base: "100%", md: "50%" }}
          mb={{ base: 0, md: 3 }}
          mr={{ base: 3, md: 0 }}
        >
          <Image src={menuItem.url} objectFit="cover" w="100%" h="100%" />
        </Box>
        <Flex flexDirection="column" fontSize={[14, 18]}>
          <Text fontWeight={600}>{name}</Text>
          <Text fontStyle="italic">{description?.toLowerCase()}</Text>
          <Text fontWeight={600}>${cost}</Text>
        </Flex>
      </Flex>

      {/* <Tr
        _hover={{ backgroundColor: "gray.100", fontWeight: 700 }}
        onClick={onOpen}
        cursor="pointer"
      >
        <Td w="35%">{name}</Td>
        <Td w="35%">{description}</Td>
        <Td w="10%" isNumeric>
          {cost}
        </Td>
        <Td w="20%" textAlign="center">
          <Image src={menuItem.url} objectFit="cover" />
        </Td>

      </Tr> */}

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered scrollBehavior="inside">
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(90deg)" />
        <ModalContent>
          <ModalHeader>Update Menu Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>Item Name</FormLabel>
              <Input
                id="name"
                type="name"
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter the name of food item here"
                value={name}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Input
                id="description"
                type="description"
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter description of food here."
                value={description}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Cost</FormLabel>
              <Input
                id="cost"
                type="cost"
                onChange={(e) => setCost(e.target.value)}
                placeholder="Enter cost of food here."
                value={cost}
              />
              <FormHelperText>
                Enter full dollar value without dollar sign. Ex: If $5.00 enter "5.00". If $5.50
                enter "5.50"
              </FormHelperText>
            </FormControl>
            <FormControl isRequired my={3}>
              <FormLabel>Category</FormLabel>
              <Select
                placeholder="Select food category"
                onChange={(e) => setCategory(e.target.value)}
                value={category}
              >
                <option value="BREAD">BREAD</option>
                <option value="DAIRY">DAIRY</option>
                <option value="DRINKS">DRINKS</option>
                <option value="FRUITS">FRUITS</option>
                <option value="MEAT">MEAT</option>
                <option value="MISCELLANEOUS">MISCELLANEOUS</option>
                <option value="VEGETABLES">VEGETABLES</option>

                {/* <option value="Meat">Meat</option>
                  <option value="Fruits & Vegetables">Fruits & Vegetables</option>
                  <option value="Miscellaneous">Miscellaneous</option> */}
              </Select>
            </FormControl>
            <Tabs>
              <TabList>
                <Tab>Image URL</Tab>
                <Tab onClick={handleImageSearch}>Image Search</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <FormControl>
                    <Flex flexDir="column" alignItems="center" justifyContent="center" w="100%">
                      <Input
                        id="image-url"
                        type="text"
                        onChange={(e) => setImageUrl(e.target.value)}
                        placeholder="Set Image URL of food item here."
                        value={imageUrl}
                      />

                      {imageUrl ? (
                        <Image src={imageUrl} objectFit="cover" w="100%" maxH="400px" />
                      ) : null}
                    </Flex>
                  </FormControl>
                </TabPanel>
                <TabPanel>
                  <Flex flexWrap="wrap" w="100%" justifyContent="space-evenly" pb={5}>
                    {searchedImages
                      ? searchedImages.map((image, index) => (
                          <ImageResult
                            url={image.src.large}
                            selectedImage={selectedImage}
                            setSelectedImage={setSelectedImage}
                            index={index}
                            setImageUrl={setImageUrl}
                          />
                        ))
                      : null}
                    {noImgResults ? (
                      <Text letterSpacing={3} mt={5} fontSize="20px">
                        NO IMAGE RESULTS
                      </Text>
                    ) : null}
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Flex>
              <Tooltip label="Update" hasArrow placement="top">
                <IconButton
                  isLoading={loading}
                  icon={<AiFillSave />}
                  colorScheme="blue"
                  zIndex={0}
                  onClick={updateItem}
                />
              </Tooltip>
              <Box boxSize={3} />
              <Tooltip label="Delete" hasArrow placement="top">
                <DeleteConfirmation
                  event={deleteItem}
                  loading={deleteLoading}
                  question="Would you really like to delete this menu item?"
                />
              </Tooltip>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default function ProductCards({ menuData, addNewCartItem, getAllMenuItems }) {
  const logMe = () => {
    console.log(menuData);
  };

  return (
    <Flex w="100%" flexWrap="wrap" justifyContent="flex-start">
      {menuData
        ? menuData.map((item, index) => (
            <Card
              key={`${item.name}-${index}`}
              menuItem={item}
              addNewCartItem={addNewCartItem}
              getAllMenuItems={getAllMenuItems}
            />
          ))
        : null}
    </Flex>

    // <Table variant="simple" m={0}>
    //   {/* <Button onClick={logMe}>ADD IDS</Button> */}
    //   <Thead m={0}>
    //     <Tr backgroundColor="gray.200" zIndex={1} position="sticky" top={230} h="50px">
    //       <Th>Item Name</Th>
    //       <Th>Description</Th>
    //       <Th>Cost</Th>
    //       <Th></Th>
    //       {/* <Th></Th> */}

    //       {/* <Th textAlign="center"></Th> */}
    //     </Tr>
    //   </Thead>
    //   <Tbody>
    //     {menuData
    //       ? menuData.map((item, index) => (
    //           <TableRow
    //             key={`${item.name}-${index}`}
    //             menuItem={item}
    //             addNewCartItem={addNewCartItem}
    //             getAllMenuItems={getAllMenuItems}
    //           />
    //         ))
    //       : null}
    //   </Tbody>
    // </Table>
  );
}
