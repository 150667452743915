import { Flex, Text } from "@chakra-ui/react";
import React from "react";

import "./header.css";

export default function Footer() {
  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      backgroundColor="white"
      w="100%"
      p={5}
    >
      <Text fontSize="10px" textAlign="justify">
        Image Disclaimer: The images used on this site are for reference only and do not reflect the
        actual product that will be given. Any images used on this site are not owned by the domain
        and just serve as representations of the products.
      </Text>
      <Text fontSize="10px">© 2024 Skynet Solutions</Text>
    </Flex>
  );
}
