import {
  IconButton,
  Input,
  Box,
  Tooltip,
  Flex,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
  useBreakpointValue,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { v4 as uuid } from "uuid";

// import "./ProductCard.css";

import { AiOutlinePlus } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";

export default function ItemCard({ menuItem, addNewCartItem }) {
  const [quantity, setQuantity] = useState(0);
  const toast = useToast();

  const handleAddNewCartItem = () => {
    toast({
      title: "Added to cart:",
      description: `${quantity} ${menuItem.name}`,
      status: "info",
      duration: 2500,
      isClosable: true,
    });
    addNewCartItem(menuItem, quantity);
  };

  return (
    <Flex
      m={2}
      flexDir={{ base: "row", md: "column" }}
      w={{ base: "100%", md: "240px" }}
      h={{ base: "180px", md: "400px" }}
      borderWidth={1}
      borderColor="gray.400"
      style={{ boxShadow: "0 0px 8px 1px RGBA(0, 0, 0, 0.24)" }}
      _hover={{ boxShadow: "0 0px 10px 1px RGBA(0, 0, 0, 0.34)" }}
      className="card"
      p={4}
      cursor="pointer"
      borderRadius={5}
      alignItems={{ base: "center", md: "flex-start" }}
      // onClick={() => console.log(menuItem)}
    >
      <Box w="100%" h={{ base: "100%", md: "50%" }} mb={{ base: 0, md: 3 }} mr={{ base: 3, md: 0 }}>
        <Image src={menuItem.url} objectFit="cover" w="100%" h="100%" />
      </Box>
      <Flex flexDir="column" h={{ base: "100%", md: "50%" }} justify="space-between" w="100%">
        <Flex flexDirection="column" fontSize={[14, 18]}>
          <Text fontWeight={600}>{menuItem.name}</Text>
          <Text fontStyle="italic">{menuItem.description?.toLowerCase()}</Text>
          <Text fontWeight={600}>${menuItem.cost}</Text>
        </Flex>
        <Flex w="100%">
          <Box w="65%" mr={4}>
            <NumberInput
              defaultValue={0}
              min={0}
              onChange={(e) => setQuantity(e)}
              zIndex={0}
              size="lg"
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
          <IconButton
            w="35%"
            size="lg"
            colorScheme="blue"
            icon={<AiOutlinePlus />}
            isDisabled={quantity < 1 ? true : false}
            onClick={handleAddNewCartItem}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
