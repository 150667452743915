import {
  Flex,
  Button,
  AlertIcon,
  AlertTitle,
  Alert,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tooltip,
  IconButton,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { BsTrashFill } from "react-icons/bs";

export default function DeleteConfirmation({ event, question, label }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    setSuccess(false);
    setError(false);
    await event();
    setSuccess(true);
    setTimeout(() => {
      setLoading(false);
      setSuccess(false);
      setError(false);
      onClose();
    }, 2000);
  };

  return (
    <>
      <Tooltip label={label} hasArrow placement="top">
        <IconButton
          isLoading={loading}
          icon={<BsTrashFill />}
          zIndex={0}
          colorScheme="red"
          onClick={onOpen}
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justify="center" alignSelf="center" flexDir="column">
              <Text mb={3}>{question}</Text>
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>This cannot be undone.</AlertTitle>
              </Alert>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex justify="flex-end" w="100%">
              {success ? (
                <Alert status="success">
                  <AlertIcon />
                  <AlertTitle>Success!</AlertTitle>
                </Alert>
              ) : null}
              {error ? (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle>Error!</AlertTitle>
                </Alert>
              ) : null}
            </Flex>
            <Button
              w="150px"
              ml={2}
              colorScheme="red"
              isDisabled={loading}
              isLoading={loading}
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
