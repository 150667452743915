import {
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import NewItemForm from "./NewItemForm";
import { FcSearch } from "react-icons/fc";
import { BiReset } from "react-icons/bi";
import { useEffect } from "react";

export default function MenuNavBar({
  setShowMenu,
  showMenu,
  menuData,
  setSearchField,
  handleFilterSearch,
}) {
  const allText = useBreakpointValue(["xs", "md"]);
  const formElementSize = useBreakpointValue(["md", "lg"]);
  const flexDirectionBp = useBreakpointValue(["column", "row"]);
  const dividerOrientation = useBreakpointValue(["horizontal", "vertical"]);
  const containerWidthBp = useBreakpointValue(["100%", "50%"]);
  const inputWidthBp = useBreakpointValue(["100%", "32%"]);
  const selectWidth = useBreakpointValue(["100%", "18%"]);
  const [searchValue, setSearchValue] = useState("");
  const [categoryValue, setCategoryValue] = useState("");

  const handleSelect = () => {
    if (categoryValue) {
      setShowMenu(categoryValue);
    } else {
      setShowMenu("ALL");
    }
  };

  const handleResetButton = () => {
    setSearchValue("");
    setCategoryValue("");
  };

  useEffect(() => {
    setShowMenu("ALL");
  }, []);

  useEffect(() => {
    if (menuData) {
      if (!searchValue) {
        // event.target.reset();
        setCategoryValue("");
        handleFilterSearch("");
      } else {
        setCategoryValue("");
        handleFilterSearch(searchValue);
      }
    }
  }, [searchValue]);

  useEffect(() => {
    if (menuData) {
      handleSelect();
    }
  }, [categoryValue]);

  return (
    <Flex alignItems="center" py={2} zIndex={1} backgroundColor="white" w="100%">
      <Flex
        justifyContent="flex-end"
        alignItems="center"
        w="100%"
        flexDir={flexDirectionBp}
        // backgroundColor="gray.200"
      >
        <Select
          value={categoryValue}
          placeholder="SELECT CATEGORY"
          w={selectWidth}
          onChange={(e) => setCategoryValue(e.target.value)}
          size={formElementSize}
          mx={1}
          my={1}
        >
          <option value="ALL">🙌 ALL</option>
          <option value="BREAD">🥐 BREAD</option>
          <option value="DAIRY">🥛 DAIRY</option>
          <option value="DRINKS">🥤 DRINKS</option>
          <option value="FRUITS">🍎 FRUITS</option>
          <option value="MEAT">🥩 MEAT</option>
          <option value="MISCELLANEOUS">🍕 MISCELLANEOUS</option>
          <option value="VEGETABLES">🥦 VEGETABLES</option>
        </Select>
        <InputGroup size={formElementSize} w={inputWidthBp} my={1} mx={1}>
          <Input
            value={searchValue}
            size={formElementSize}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Start typing to search..."
            _placeholder={{ color: "blue.500", fontStyle: "italic" }}
            mr={{ base: 0, md: 1 }}
          />
          <InputRightElement pr={1}>
            <Icon
              cursor="pointer"
              boxSize={6}
              as={BiReset}
              onClick={handleResetButton}
              _hover={{ color: "blue.300" }}
            />
            {/* <IconButton icon={<FcSearch />} h="100%" w="100%" onClick={handleFilterSearch} /> */}
          </InputRightElement>
        </InputGroup>
      </Flex>
    </Flex>
  );
}
