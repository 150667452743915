import { Flex, Text, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";

import { db } from "../../firebase";
import { setDoc, doc } from "firebase/firestore";

import moment from "moment";

import Cart from "../components/shopping-cart/Cart";
import OrderForm from "../components/order-form/OrderForm";
import { send } from "emailjs-com";
import Login from "./Login";

export default function Order({
  cartItems,
  setCartItems,
  sessionId,
  setShowPage,
  currentUser,
  setCurrentUser,
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [finalTotalCost, setFinalTotalCost] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const submitOrder = async (order, orderInfo) => {
    if (order && order.length < 1) {
      alert("Your cart is empty.");
      return;
    }

    setLoading(true);

    // console.log("order::", order);
    const newOrder = {
      order,
      orderInfo,
      orderId: sessionId,
      totalCost: finalTotalCost,
      status: "pending",
      orderDate: moment().format("l"),
      uid: currentUser.uid,
    };

    // console.log("orderInfo.dateOfVisit:", orderInfo.dateOfVisit);

    const emailDetails = {
      from_name: orderInfo.visitorName,
      date_of_visit: moment(orderInfo.dateOfVisit).format("M-D-YYYY"),
      visitor_name: orderInfo.visitorName,
      visitor_email: orderInfo.visitorEmail,
      inmate_name: orderInfo.inmateName,
      inmate_cdcr: orderInfo.inmateCdcr,
      cc_email: orderInfo.visitorEmail,
      order: JSON.stringify(order, null, "&nbsp;")
        .split("\n")
        .join("<br>")
        .replace(/[[\]"{},]+/g, ""),
      order_notes: orderInfo.notes,
      total_cost: finalTotalCost,
      // orderId: sessionId,
    };
    // console.log("emailDetails:", emailDetails);

    try {
      const dbResult = await setDoc(doc(db, "orders", sessionId), newOrder);
      const emailResult = await sendEmail(emailDetails);
      setSuccess(true);
      setLoading(false);
      setCartItems([]);
      console.log("submit!");
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }

    // console.log("newOrder:", newOrder);
  };

  const sendEmail = async (emailContents) => {
    const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPATE_ID;
    const PUB_KEY = process.env.REACT_APP_EMAILJS_PUB_KEY;

    try {
      const result = await send(SERVICE_ID, TEMPLATE_ID, emailContents, PUB_KEY);
      // console.log(result.status);
      // console.log(result.text);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Flex w="100%" flexDir="column">
      {!success ? (
        <>
          <Cart
            cartItems={cartItems}
            setCartItems={setCartItems}
            setFinalTotalCost={setFinalTotalCost}
            setShowPage={setShowPage}
          />
          {currentUser ? (
            <OrderForm
              cartItems={cartItems}
              submitOrder={submitOrder}
              loading={loading}
              setLoading={setLoading}
              currentUser={currentUser}
            />
          ) : (
            <Flex flexDir="column" justifyContent="center" alignItems="center">
              <Login isOpen={isOpen} onClose={onClose} setCurrentUser={setCurrentUser} />
            </Flex>
          )}
        </>
      ) : (
        <Flex w="100%" flexDir="column" py="20%">
          <Text fontSize={20} letterSpacing={3} textAlign="center">
            THANK YOU!
          </Text>
          <Text fontSize={20} letterSpacing={3} textAlign="center">
            YOUR ORDER HAS BEEN SUBMITTED SUCCESSFULLY.
          </Text>
          <Text fontSize={20} letterSpacing={3} textAlign="center">
            IF YOU PROVIDED YOUR EMAIL, YOU WILL RECEIVE AN EMAIL COPY OF YOUR ORDER.
          </Text>
        </Flex>
      )}
    </Flex>
  );
}
