export const formatDollarValue = inputString => {
  // Remove all characters except digits and decimal point
  let numericString = inputString.replace(/[^0-9.]+/g, '')

  // Remove all decimal points except the first one
  let parts = numericString.split('.')
  if (parts.length > 1) {
    numericString = parts.shift() + '.' + parts.join('')
  }

  // Parse the string as a float and format it to two decimal places
  numericString = parseFloat(numericString).toFixed(2).toString()

  return numericString
}
