import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  forwardRef,
  Input,
  Text,
  Textarea,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDollarValue } from "../../functions/formatDollarValue";

export default function OrderForm({ cartItems, submitOrder, loading, currentUser }) {
  const [complete, setComplete] = useState(false);
  const [visitorName, setVisitorName] = useState("");
  const [inmateName, setInmateName] = useState("");
  const [inmateCdcr, setInmateCdcr] = useState("");
  const [dateOfVisit, setDateOfVisit] = useState(new Date(moment().weekday(7)));
  const [notes, setNotes] = useState("");
  const [visitorEmail, setVisitorEmail] = useState("");
  const buttonSize = useBreakpointValue(["sm", "lg"]);
  const inputSize = useBreakpointValue(["sm", "lg"]);

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <CustomInput onClick={onClick} ref={ref} value={value} />
  ));

  const CustomInput = ({ onClick, ref, value }) => {
    return (
      <Button w="100%" onClick={onClick} ref={ref} pr="40px" variant="outline">
        {value}
      </Button>
    );
  };

  const disabledDate = (date) => {
    const thisDate = new Date(date);
    const day = thisDate.getDay();
    return day !== 1 && day !== 2 && day !== 3 && day !== 5 && day !== 6;
  };

  const validateEmail = (e) => {
    if (e.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    if (!visitorName) {
      alert("Please enter visitor's name.");
      return;
    }
    if (!inmateName) {
      alert("Please enter inmates's name.");
      return;
    }
    if (!inmateCdcr) {
      alert("Please enter inmates's CDCR#.");
      return;
    }
    if (!dateOfVisit) {
      alert("Please select a date of visit.");
      return;
    }
    if (!visitorEmail) {
      alert("Please enter your email.");
      return;
    }
    if (visitorEmail) {
      const isValidEmail = validateEmail(visitorEmail);
      if (!isValidEmail) {
        alert("Please enter a valid email address.");
        return;
      }
    }

    const orderInfo = {
      visitorName,
      inmateName,
      inmateCdcr,
      dateOfVisit: moment(dateOfVisit).format("l"),
      notes,
      visitorEmail,
    };

    try {
      submitOrder(removeUrlFromCartItems(cartItems), orderInfo);
    } catch (error) {
      alert(error.message);
    }
  };


  const removeUrlFromCartItems = (cart) => {
    let items = [];
    cart.forEach((item) => {
      const cartItem = {
        category: item.category,
        cost: formatDollarValue(item.cost),
        description: item.description ? item.description : null,
        name: item.name,
        quantity: item.quantity,
      };

      items.push(cartItem);
    });
    return items;
  };



  return (
    <Flex flexDir="column" mt={5} borderWidth={1} p={5}>
      <Flex flexDir={["column", "row"]} justify="center" alignItems="center">
        <FormControl isRequired mb={2}>
          <FormLabel fontSize={[16, 18]}>
            NAME OF VISITOR
          </FormLabel>
          <Input
            size={inputSize}
            fontSize={[16, 18]}
            id="visitor-name"
            type="text"
            onChange={(e) => setVisitorName(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired mx={3} mb={2}>
          <FormLabel fontSize={[16, 18]}>INMATE NAME</FormLabel>
          <Input
            size={inputSize}
            fontSize={[16, 18]}
            id="inmate-name"
            type="text"
            onChange={(e) => setInmateName(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired mx={3} mb={2}>
          <FormLabel fontSize={[16, 18]}>INMATE CDCR#</FormLabel>
          <Input
            size={inputSize}
            fontSize={[12, 18]}
            id="inmate-cdcr"
            type="text"
            onChange={(e) => setInmateCdcr(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired mb={2}>
          <Flex flexDir="column">
            <Flex alignItems="center">
              <FormLabel fontSize={[16, 18]} mr={1}>
                DATE OF VISIT
              </FormLabel>
              <Text fontSize={[12, 12]} fontStyle="italic" pb={2}>
                (Thurdays and Sundays only)
              </Text>
            </Flex>

            <DatePicker
              selected={dateOfVisit}
              onChange={(date) => setDateOfVisit(date)}
              value={dateOfVisit}
              isClearable
              customInput={<DatePickerInput />}
              minDate={new Date(moment())}
              filterDate={disabledDate}
            />
          </Flex>
        </FormControl>
      </Flex>
      <Flex alignItems="flex-start" flexDir={["column", "row"]}>
        <FormControl mt={3} mb={2}>
          <Flex alignItems="center">
            <FormLabel fontSize={[16, 18]} mr={1}>
              NOTES/SUGGESTIONS
            </FormLabel>
            <Text fontSize={[10, 12]} fontStyle="italic" pb={2}>
              (Optional)
            </Text>
          </Flex>
          <Textarea
            fontSize={[16, 18]}
            placeholder="Enter notes/suggestions here."
            onChange={(e) => setNotes(e.target.value)}
          />
        </FormControl>
        <Box mx={3} />
        <FormControl mt={3} mb={2} isRequired>
          <Flex alignItems="center">
            <FormLabel fontSize={[16, 18]} mr={1}>
              EMAIL
            </FormLabel>
          </Flex>
          <Input
            size={inputSize}
            fontSize={[16, 18]}
            id="email"
            type="email"
            onChange={(e) => setVisitorEmail(e.target.value)}
            placeholder="To receive a copy of your order."
          />
        </FormControl>
      </Flex>
      <Text letterSpacing="1px" mt={5} mb={4} fontSize={[14, 18]} textAlign="center" w="100%">
        PLEASE ENSURE THAT ALL INFORMATION IS ACCURATE.
      </Text>
      <Flex m="auto" flexDir="column" alignItems="center" mt={5} w={{base: "100%", med: "50%"}}>
        <Tooltip
          label={cartItems.length < 1 ? "Please add items to cart." : null}
          hasArrow
          placement="top"
          shouldWrapChildren
        >
          <Button
            w="300px"
            isLoading={loading}
            colorScheme="blue"
            loadingText="Submitting Order"
            onClick={handleSubmit}
            isDisabled={complete || cartItems.length < 1}
            size={buttonSize}
          >
            {complete ? "SUBMITTED" : "SUBMIT ORDER"}
          </Button>
        </Tooltip>

        <Text textAlign="center" mt={10} fontSize={[14, 18]}>
          CANCELLATIONS MUST BE SUBMITTED WITHIN 7 DAYS OF VISIT VIA EMAIL OR THERE WILL BE A $20
          RESTOCKING FEE UPON NEXT VISIT
        </Text>
        <Text textAlign="center" mt={1} fontSize={[14, 18]} color="red.500" fontWeight={700}>
          DAY OF CANCELLATION PRODUCT MUST BE PAID IN FULL
        </Text>
        <Text textAlign="center" mt={1} fontSize={[14, 18]} textDecor="underline">
          ALL SALES ARE FINAL
        </Text>
        <Text textAlign="center" mt={1} fontSize={[14, 18]} fontWeight={700}>
          BY CLICKING SUBMIT ORDER, I STATE THAT I HAVE READ AND UNDERSTOOD THE TERMS AND CONDITIONS
          PROVIDED ABOVE
        </Text>
      </Flex>
    </Flex>
  );
}
