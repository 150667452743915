import { Accordion, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";

import { db } from "../../firebase";
import { getDocs, collection, where, query } from "firebase/firestore";

import moment from "moment";
import MyOrdersTable from "../components/my-accounts/MyOrdersTable";
import { useEffect } from "react";

export default function MyOrders({ myUid }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [myOrders, setMyOrders] = useState();

  const getMyOrders = async () => {
    const data = [];
    const q = query(collection(db, "orders"), where("uid", "==", "XECXodjhOudsWVW2Q0i0YrER6fv1"));

    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const order = {
          order: doc.data().order,
          orderDate: doc.data().orderDate,
          orderId: doc.data().orderId,
          orderInfo: doc.data().orderInfo,
          status: doc.data().status,
          totalCost: doc.data().totalCost,
          uid: doc.data().uid,
        };
        // console.log(doc.data());
        // console.log(doc.id, doc.data());
        data.push(order);
      });
      console.log("data:", data);
      setMyOrders(data);
    } catch (error) {
      console.log(error);
    }
  };

  const logMe = () => {
    console.log("myOrders::", myOrders);
    getMyOrders();
  };

  useEffect(() => {
    getMyOrders();
  }, []);

  return (
    <Flex w="100%" justify="flex-start" flexDir="column">
      <Button onClick={logMe}>Get My Orders</Button>
      {/* <Text>UID: {myUid}</Text>
      <Text fontSize={20} letterSpacing={3} onClick={() => console.log(myOrders)}>
        ORDERS
      </Text> */}
      <Accordion allowMultiple allowToggle>
        {myOrders ? myOrders.map((order) => <MyOrdersTable order={order} />) : null}
      </Accordion>
    </Flex>
  );
}
