import {
  Box,
  Button,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Image,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { BsFillTrashFill } from "react-icons/bs";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { formatDollarValue } from "../../functions/formatDollarValue";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export default function CartItem({
  cartItems,
  item,
  index,
  updateCartItemQuantity,
  removeCartItem,
  calculateTotalCost,
}) {
  const [quantity, setQuantity] = useState(item.quantity);
  const [totalRowCost, setTotalRowCost] = useState();
  const inputSize = useBreakpointValue(["md", "lg"]);
  const buttonSize = useBreakpointValue(["md", "md"]);
  const rowHeight = useBreakpointValue(["45px", null]);
  const toast = useToast();

  const calculateTotal = () => {
    if (cartItems.length < 1) {
      setTotalRowCost(0);
      return;
    }
    const total = Number(formatDollarValue(item.cost)) * Number(quantity);
    setTotalRowCost(total);
  };

  const setStates = () => {
    setQuantity(item.quantity);
  };

  const handleRemoveCartItem = () => {
    removeCartItem(index, item);

    toast({
      title: "Removed from cart:",
      description: `${item.name}`,
      status: "warning",
      duration: 2500,
      isClosable: true,
    });
  };

  const handleQuantityChange = (e) => {
    // console.log(e);
    if (e === "0") {
      removeCartItem(index, item);

      toast({
        title: "Removed from cart:",
        description: `${item.name}`,
        status: "warning",
        duration: 2500,
        isClosable: true,
      });
    } else {
      setQuantity(e);
    }
  };

  const handleBlur = () => {
    if (quantity === "") {
      removeCartItem(index, item);
  
      toast({
        title: "Removed from cart:",
        description: `${item.name}`,
        status: "warning",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    setQuantity(item.quantity);
    // console.log("TableRow:", item.quantity);
  }, [cartItems]);

  useEffect(() => {
    calculateTotal();
  }, [quantity, cartItems]);

  useEffect(() => {
    updateCartItemQuantity(quantity, index);
  }, [quantity]);

  return (
    <>
      <Flex
        flexDir={{base: "column", md: "row"}}
        w="100%"
        // h={{ base: "100px", md: "400px" }}
        p={{ base: 2, md: 3 }}
        alignItems="center"
        justify="space-between"
        borderWidth={1}
        borderColor="gray.300"
        // onClick={() => console.log(menuItem)}
      >
        <Flex alignItems="center" w="100%" h="100%">
          <Flex
            w={{ base: "30%", md: "150px" }}
            h={{ base: "60%", md: "150px" }}
            mr={{ base: 1, md: 4 }}
          >
            <Image
              src={item.url}
              objectFit="cover"
              justifyContent={{base: "center", md: "flex-start"}}
              w={{ base: "100%", md: "150px" }}
              h={{ base: "100%", md: "150px" }}
            />
          </Flex>

          <Flex flexDirection="column" pl={{base: 3, md: 0}} fontSize={[14, 18]}>
            <Text fontWeight={600}>{item.name}</Text>
            <Text fontStyle="italic">{item.description?.toLowerCase()}</Text>
          </Flex>
        </Flex>

        <Flex w={{base: "100%", md: "auto"}} justifyContent={{base: "center", md: "flex-start"}} p={{ base: 1, md: 4 }} alignItems="center" fontSize={[13, 18]}>
          <Text textAlign="center" w={{ base: "20%", md: "150px" }} fontWeight={600}>
            ${item.cost} <span style={{ fontWeight: 200 }}>/ea</span>
          </Text>
          <Text textAlign="center" w={{ base: "10%", md: "100px" }} fontWeight={600}>
            x
          </Text>

          <Flex flexDir="column" w={{ base: "25%", md: "100px" }}>
            <NumberInput
              allowMouseWheel
              defaultValue={quantity}
              value={quantity}
              min={0}
              onChange={handleQuantityChange}
              onBlur={handleBlur}
              zIndex={0}
              size={inputSize}
              step={1}
              precision={0}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Flex>

          <Text
            textAlign="center"
            w={{ base: "10%", md: "100px" }}
            fontWeight={600}
            mr={{ base: 0, md: 4 }}
          >
            =
          </Text>

          <Text textAlign="center" w={{ base: "20%", md: "100px" }} mr={{ base: 0, md: 4 }}>
            {formatter.format(totalRowCost)}
          </Text>

          <Flex w={{ base: "15%", md: "100px" }} justifyContent={{base: "center", md: "flex-start"}}>
            <Tooltip label="Remove from cart" hasArrow placement="top">
              <IconButton
                icon={<BsFillTrashFill />}
                onClick={handleRemoveCartItem}
                size={buttonSize}
              />
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
