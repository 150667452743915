import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { BsFillTrashFill } from "react-icons/bs";
import { AiOutlineArrowLeft } from "react-icons/ai";
import CartItem from "./CartItem";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const TableRow = ({ cartItems, item, index, updateCartItemQuantity, removeCartItem }) => {
  const [quantity, setQuantity] = useState(item.quantity);
  const [totalRowCost, setTotalRowCost] = useState();
  const inputSize = useBreakpointValue(["xs", "sm"]);
  const buttonSize = useBreakpointValue(["xs", "md"]);
  const rowHeight = useBreakpointValue(["45px", null]);
  const toast = useToast();

  const calculateTotal = () => {
    if (cartItems.length < 1) {
      setTotalRowCost(0);
      return;
    }
    const total = Number(item.cost) * Number(quantity);
    setTotalRowCost(total);
  };

  const setStates = () => {
    setQuantity(item.quantity);
  };

  const handleRemoveCartItem = () => {
    removeCartItem(index, item);
    toast({
      title: "Removed from cart:",
      description: `${item.name}`,
      status: "warning",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleQuantityChange = (e) => {
    // console.log(e);
    if (e === "0") {
      removeCartItem(index);
    } else {
      setQuantity(e);
    }
  };

  useEffect(() => {
    setQuantity(item.quantity);
    // console.log("TableRow:", item.quantity);
  }, [cartItems]);

  useEffect(() => {
    calculateTotal();
  }, [quantity, cartItems]);

  useEffect(() => {
    updateCartItemQuantity(quantity, index);
  }, [quantity]);

  return (
    <Tr h={rowHeight}>
      <Td
        w="30%"
        letterSpacing={[0, 4]}
        onClick={() => console.log("item:", item)}
        fontSize={[10, 16]}
      >
        {item.name.toUpperCase()}
      </Td>
      <Td w="30%" letterSpacing={[0, 2]} fontSize={[10, 16]}>
        {item.description ? item.description.toUpperCase() : ""}
      </Td>
      <Td w="10%" fontSize={[10, 16]} textAlign="center">
        {formatter.format(item.cost)}
      </Td>
      <Td w="15%" textAlign="right" fontSize={[10, 16]}>
        <Flex justify="center">
          <NumberInput
            defaultValue={quantity}
            value={quantity}
            min={0}
            onChange={handleQuantityChange}
            zIndex={0}
            size={inputSize}
          >
            <NumberInputField fontSize={[10, 16]} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
      </Td>
      <Td w="5%" fontSize={[10, 16]} textAlign="center">
        {formatter.format(totalRowCost)}
      </Td>
      <Td w="5%">
        <Tooltip label="Remove from cart" hasArrow placement="top">
          <IconButton icon={<BsFillTrashFill />} onClick={handleRemoveCartItem} size={buttonSize} />
        </Tooltip>
      </Td>
    </Tr>
  );
};

export default function Cart({ cartItems, setCartItems, setFinalTotalCost, setShowPage }) {
  const [totalCost, setTotalCost] = useState();
  const iconSize = useBreakpointValue([12, 18]);
  const tableSize = useBreakpointValue(["xs", "md"]);
  const headerTextAlignLeft = useBreakpointValue(["center", "left"]);
  const headerTextAlignRight = useBreakpointValue(["center", "right"]);

  const logMe = () => {
    console.log(cartItems);
  };

  const calculateTotalCost = () => {
    let totalCost = 0;
    cartItems.forEach((item) => {
      totalCost += Number(item.cost) * Number(item.quantity);
    });
    setTotalCost(totalCost);
    setFinalTotalCost(formatter.format(totalCost));
  };

  const updateCartItemQuantity = (qty, index) => {
    const cartItemsArray = cartItems;
    cartItemsArray[index].quantity = qty;

    setCartItems(cartItemsArray);
    calculateTotalCost();
  };

  const removeCartItem = (index, item) => {
    const cartItemsArray = [...cartItems];
    // console.log(cartItemsArray[index]);
    // console.log(item);
    cartItemsArray.splice(index, 1);
    // console.log("cartItems:", cartItems);

    setCartItems(cartItemsArray);
    calculateTotalCost();
  };

  useEffect(() => {
    calculateTotalCost();
  }, [cartItems]);

  return (
    <>
      <Flex alignItems="center" mb={4}>
        <AiOutlineArrowLeft fontSize={iconSize} />
        <Link ml={2} onClick={() => setShowPage("menu")} fontSize={[12, 16]}>
          Back to Menu
        </Link>
      </Flex>
      <Flex flexDir="column" borderWidth={1} pt={2}>
        <Text
          textAlign="left"
          pl={[4, 8]}
          mb={3}
          fontSize={[22, 38]}
          letterSpacing="8px"
        >
          CART
        </Text>
        {/* <Button onClick={logMe}>logMe</Button> */}
        {cartItems && cartItems.length > 0 ? (
          // <Table variant="simple" size={tableSize}>
          //   <Thead>
          //     <Tr>
          //       <Th w="30%" fontSize={[10, 16]} textAlign="left">
          //         Item
          //       </Th>
          //       <Th w="30%" fontSize={[10, 16]} textAlign="left">
          //         Description
          //       </Th>
          //       <Th w="15%" fontSize={[10, 16]} textAlign={headerTextAlignRight}>
          //         Cost
          //       </Th>
          //       <Th w="5%" fontSize={[10, 16]} textAlign="center">
          //         QTY
          //       </Th>
          //       <Th w="10%" fontSize={[10, 16]} textAlign={headerTextAlignRight}>
          //         Total
          //       </Th>
          //       <Th w="10%"></Th>
          //     </Tr>
          //   </Thead>
          //   <Tbody>
          <>
            {cartItems && cartItems.length > 0
              ? cartItems.map((item, index) => (
                  // <TableRow
                  //   key={`${item.name}-${index}`}
                  //   item={item}
                  //   index={index}
                  //   updateCartItemQuantity={updateCartItemQuantity}
                  //   removeCartItem={removeCartItem}
                  //   cartItems={cartItems}
                  // />
                  <CartItem
                    key={`${item.name}-${index}`}
                    item={item}
                    index={index}
                    updateCartItemQuantity={updateCartItemQuantity}
                    removeCartItem={removeCartItem}
                    cartItems={cartItems}
                    calculateTotalCost={calculateTotalCost}
                  />
                ))
              : null}
            <Flex
              flexDir="row"
              w="100%"
              // h={{ base: "100px", md: "400px" }}
              p={{ base: 0, md: 3 }}
              alignItems="center"
              justify="space-between"
              borderWidth={1}
              bg="gray.200"
              // onClick={() => console.log(menuItem)}
            >
              <Flex alignItems="center">
                <Box
                  w={{ base: "75px", md: "150px" }}
                  h={{ base: "25px", md: "50px" }}
                  mr={{ base: 1, md: 4 }}
                />
                <Flex flexDirection="column" mr={{ base: 1, md: 4 }} />
              </Flex>

              <Flex p={{ base: 1, md: 4 }} mr={{base: 10, md: 0}} alignItems="center" fontSize={[16, 22]} justifyContent={{base: "flex-start", md: "center"}}>
                <Text
                  textAlign="center"
                  w={{ base: "10px", md: "150px" }}
                  mr={{ base: 1, md: 4 }}
                />

                <Text
                  textAlign="center"
                  w={{ base: "1px", md: "100px" }}
                  mr={{ base: 1, md: 4 }}
                />
                <Flex flexDir="column" w={{ base: "1px", md: "100px" }} mr={{ base: 1, md: 4 }} />
                <Text
                  textAlign="center"
                  w={{ base: "100px", md: "100px" }}
                  fontWeight={600}
                  mr={{ base: 1, md: 4 }}
                >
                  TOTAL:
                </Text>
                <Text
                  textAlign="center"
                  fontWeight={600}
                  w={{ base: "100px", md: "100px" }}
                  mr={{ base: 1, md: 4 }}
                >
                  {formatter.format(totalCost)}
                </Text>
                <Flex w={{ base: "10px", md: "100px" }} />
              </Flex>
            </Flex>
          </>
        ) : (
          <Flex justify="center" alignItems="center" my="5%">
            <Text fontSize={[14, 24]} letterSpacing="4px">
              NO ITEMS IN CART
            </Text>
          </Flex>
        )}
      </Flex>
    </>
  );
}
