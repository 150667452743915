import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Input,
  Box,
  Tooltip,
  Flex,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Select,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { db } from "../../../../firebase";
import { collection, doc, setDoc, deleteDoc } from "firebase/firestore";
import { v4 as uuid } from "uuid";

import { AiFillSave } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import DeleteConfirmation from "../../../modals/DeleteConfirmation";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const TableRow = ({ menuItem, getAllMenuItems }) => {
  const formatCost = () => {
    let newCost;
    if (menuItem.cost.includes(".")) {
      const costArray = menuItem.cost.split(".");

      if (costArray[1].length > 1) {
        newCost = menuItem.cost;
      } else {
        newCost = `$${menuItem.cost}.0`;
      }
    } else {
      newCost = `${menuItem.cost}.00`;
    }
    return newCost;
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [name, setName] = useState(menuItem.name.toUpperCase());
  const [description, setDescription] = useState(
    menuItem.description ? menuItem.description.toUpperCase() : ""
  );
  const [cost, setCost] = useState(formatCost());
  const [category, setCategory] = useState(menuItem.category.toUpperCase());
  const [imageUrl, setImageUrl] = useState(menuItem.url ? menuItem.url : null);

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const updateItem = async () => {
    setLoading(true);

    const data = {
      name,
      description: description ? description : null,
      cost,
      category,
      url: imageUrl,
    };

    const productRef = doc(db, "menu", menuItem.id);
    try {
      const result = await setDoc(productRef, data, { merge: true });
      // console.log("updateItem:", result);
      setLoading(false);
      getAllMenuItems();
      onClose();
    } catch (error) {
      alert(error.message);
    }
  };

  const deleteItem = async () => {
    setDeleteLoading(true);

    const deleteRef = doc(db, "menu", menuItem.id);

    try {
      const result = await deleteDoc(deleteRef);
      // console.log("deleteItem result:", result);
      setDeleteLoading(false);
      getAllMenuItems();
      onClose();
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <>
      <Tr
        _hover={{ backgroundColor: "gray.100", fontWeight: 700 }}
        onClick={onOpen}
        cursor="pointer"
      >
        <Td w="35%">{name}</Td>
        <Td w="35%">{description}</Td>
        <Td w="10%" isNumeric>
          {cost}
        </Td>
        <Td w="20%" textAlign="center">
          <Image src={menuItem.url} objectFit="cover" />
        </Td>

        {/* <Td w="10%" textAlign="center"></Td> */}
      </Tr>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Menu Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired my={3}>
              <FormLabel>Item Name</FormLabel>
              <Input
                id="name"
                type="name"
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter the name of food item here"
                value={name}
              />
            </FormControl>
            <FormControl my={3}>
              <FormLabel>Description</FormLabel>
              <Input
                id="description"
                type="description"
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter description of food here."
                value={description}
              />
            </FormControl>
            <FormControl isRequired my={3}>
              <FormLabel>Cost</FormLabel>
              <Input
                id="cost"
                type="text"
                onChange={(e) => setCost(e.target.value)}
                placeholder="Enter cost of food here."
                value={cost}
              />
              <FormHelperText>
                Enter full dollar value without dollar sign. Ex: If $5.00 enter "5.00". If $5.50
                enter "5.50"
              </FormHelperText>
            </FormControl>
            <FormControl isRequired my={3}>
              <FormLabel>Category</FormLabel>
              <Select
                placeholder="Select food category"
                onChange={(e) => setCategory(e.target.value)}
                value={category}
              >
                <option value="BREAD">BREAD</option>
                <option value="DAIRY">DAIRY</option>
                <option value="DRINKS">DRINKS</option>
                <option value="FRUITS">FRUITS</option>
                <option value="MEAT">MEAT</option>
                <option value="MISCELLANEOUS">MISCELLANEOUS</option>
                <option value="VEGETABLES">VEGETABLES</option>

                {/* <option value="Meat">Meat</option>
                  <option value="Fruits & Vegetables">Fruits & Vegetables</option>
                  <option value="Miscellaneous">Miscellaneous</option> */}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Image URL</FormLabel>
              <Flex flexDir="column" alignItems="center" justifyContent="center" w="100%">
                <Input
                  id="image-url"
                  type="text"
                  onChange={(e) => setImageUrl(e.target.value)}
                  placeholder="Set Image URL of food item here."
                  value={imageUrl}
                />
                {imageUrl ? <Image src={imageUrl} objectFit="cover" w="100%" maxH="500px" /> : null}
              </Flex>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Flex>
              <Tooltip label="Update" hasArrow placement="top">
                <IconButton
                  isLoading={loading}
                  icon={<AiFillSave />}
                  colorScheme="blue"
                  zIndex={0}
                  onClick={updateItem}
                />
              </Tooltip>
              <Box boxSize={3} />
              <Tooltip label="Delete" hasArrow placement="top">
                <DeleteConfirmation
                  event={deleteItem}
                  loading={deleteLoading}
                  question="Would you really like to delete this menu item?"
                />
              </Tooltip>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default function ProductsTable({ menuData, addNewCartItem, getAllMenuItems }) {
  const logMe = () => {
    console.log(menuData);
  };

  return (
    <Table variant="simple" m={0}>
      {/* <Button onClick={logMe}>ADD IDS</Button> */}
      <Thead m={0}>
        <Tr backgroundColor="gray.200" zIndex={1} position="sticky" top={230} h="50px">
          <Th>Item Name</Th>
          <Th>Description</Th>
          <Th>Cost</Th>
          <Th></Th>
          {/* <Th></Th> */}

          {/* <Th textAlign="center"></Th> */}
        </Tr>
      </Thead>
      <Tbody>
        {menuData
          ? menuData.map((item, index) => (
              <TableRow
                key={`${item.name}-${index}`}
                menuItem={item}
                addNewCartItem={addNewCartItem}
                getAllMenuItems={getAllMenuItems}
              />
            ))
          : null}
      </Tbody>
    </Table>
  );
}
