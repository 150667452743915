import { Accordion, Box, Button, Divider, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { firebase, db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import moment from "moment";
import OrderHistoryTable from "../components/admin/order-history/OrderHistoryTable";
import OrderHistoryNavbar from "../components/admin/order-history/OrderHistoryNavbar";

export default function OrderHistory() {
  const [allOrders, setAllOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [completeOrders, setCompleteOrders] = useState([]);
  const [showMenu, setShowMenu] = useState("pending");

  const getAllOrders = async () => {
    const allOrders = [];
    const pendingOrders = [];
    const completeOrders = [];
    try {
      const querySnapshot = await getDocs(collection(db, "orders"));
      querySnapshot.forEach((doc) => {
        allOrders.push(doc.data());
        if (doc.data().status === "pending") {
          pendingOrders.push(doc.data());
        } else {
          completeOrders.push(doc.data());
        }
      });
      // console.log(allOrders);
      setAllOrders(allOrders.sort(sortByDateOfVisit));
      setPendingOrders(pendingOrders.sort(sortByDateOfVisit));
      setCompleteOrders(completeOrders.sort(sortByDateOfVisit));
    } catch (error) {
      console.log(error);
    }
  };

  const sortByDateOfVisit = (a, b) => {
    if (moment(a.orderInfo.dateOfVisit).unix() > moment(b.orderInfo.dateOfVisit).unix()) return 1;
    if (moment(a.orderInfo.dateOfVisit).unix() < moment(b.orderInfo.dateOfVisit).unix()) return -1;
    return 0;
  };

  const logMe = () => {
    console.log("pendingOrders:", pendingOrders);
    console.log("completeOrders:", completeOrders);
    // console.log("meatItems:", meatItems);
    // console.log("fruitVeggieItems:", fruitVeggieItems);
    // console.log("miscItems:", miscItems);
  };

  useEffect(() => {
    getAllOrders();
  }, []);

  return (
    <Box>
      <Flex flexDir="column" backgroundColor="white">
        {/* <Button onClick={logMe}>logMe</Button> */}

        <Flex flexDir="column" justify="flex-start">
          <Flex
            flexDir="column"
            position="sticky"
            top={82}
            zIndex={1}
            justify="flex-start"
            backgroundColor="white"
          >
            <Text letterSpacing={3} fontSize={32}>
              ORDERS
            </Text>
            <OrderHistoryNavbar
              showMenu={showMenu}
              setShowMenu={setShowMenu}
              allOrders={allOrders}
              pendingOrders={pendingOrders}
              completeOrders={completeOrders}
            />
          </Flex>

          <Accordion allowMultiple allowToggle>
            {showMenu === "pending"
              ? pendingOrders.map((order) => (
                  <OrderHistoryTable
                    key={order.orderId}
                    order={order}
                    getAllOrders={getAllOrders}
                  />
                ))
              : null}
            {showMenu === "complete"
              ? completeOrders.map((order) => (
                  <OrderHistoryTable
                    key={order.orderId}
                    order={order}
                    getAllOrders={getAllOrders}
                  />
                ))
              : null}
            {showMenu === "all"
              ? allOrders.map((order) => (
                  <OrderHistoryTable
                    key={order.orderId}
                    order={order}
                    getAllOrders={getAllOrders}
                  />
                ))
              : null}
          </Accordion>
        </Flex>
      </Flex>
    </Box>
  );
}
