import { Flex, Text } from "@chakra-ui/react";
import React from "react";

import "./header.css";

export default function Header({ setShowPage }) {
  return (
    <Flex justifyContent="flex-start" backgroundColor="white" w="100%">
      <Text
        fontSize={{ base: "30px", md: "50px" }}
        p={1}
        className="header-name"
        // fontWeight={[600, 500]}
        cursor="pointer"
        onClick={() => setShowPage("menu")}
      >
        pk-cdcr-lac.com
      </Text>
    </Flex>
  );
}
