import { Box, Image } from "@chakra-ui/react";

export const ImageResult = ({ url, selectedImage, setSelectedImage, index, setImageUrl }) => {
  const handleSelected = () => {
    setSelectedImage(index);
    setImageUrl(url);
  };

  return (
    <Box
      mb={1}
      cursor="pointer"
      className="food-img"
      onClick={handleSelected}
      // borderWidth={selectedImage === index ? 2 : null}
      // borderColor={selectedImage === index ? "blue.400" : null}
      boxShadow={selectedImage === index ? "0 0px 5px 5px #63B3ED" : null}
      style={{ tintColor: "gray" }}
    >
      <Image src={url} objectFit="cover" boxSize="150px" />
    </Box>
  );
};
