import {
  AccordionIcon,
  Box,
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { CSVLink, CSVDownload } from "react-csv";

import moment from "moment";
import { AiFillCaretDown } from "react-icons/ai";

export default function OrderHistoryNavbar({
  setShowMenu,
  showMenu,
  allOrders,
  completeOrders,
  pendingOrders,
}) {
  // const constructExcelDataOrders = (orderData) => {
  //   let excelData = [
  //     {
  //       columns: [
  //         { value: "ITEM", widthPx: 50 },
  //         { value: "QUANTITY", widthPx: 50 },
  //         { value: "COST", widthPx: 50 },
  //         { value: "DESCRIPTION", widthPx: 50 },
  //         { value: "CATEGORY", widthPx: 50 },
  //       ],
  //       data: [],
  //     },
  //   ];

  //   orderData.forEach((order) => {
  //     if (moment(order.orderInfo.dateOfVisit).isBetween(moment(), moment().add(1, "w"))) {
  //       order.order.forEach((orderItem) => {
  //         const item = [
  //           orderItem.name.toUpperCase(),
  //           orderItem.cost,
  //           parseInt(orderItem.quantity),
  //           orderItem.description?.toUpperCase(),
  //           orderItem.category?.toUpperCase(),
  //         ];

  //         excelData[0].data.push(item);
  //       });
  //     }
  //   });

  //   return excelData;
  // };

  const constructCsvDataOrders = (orderData) => {
    let csvData = [];
    orderData.forEach((completeOrder) => {
      const rowInfoData = {
        inmateCdcr: completeOrder.orderInfo.inmateCdcr,
        inmateName: completeOrder.orderInfo.inmateName,
        visitorName: completeOrder.orderInfo.visitorName,
        dateOfVisit: completeOrder.orderInfo.dateOfVisit,
        totalCost: completeOrder.totalCost,
        status: completeOrder.status,
      };
      csvData.push(rowInfoData);

      completeOrder.order.forEach((order) => {
        const orderItem = {
          item: order.name.toUpperCase(),
          cost: order.cost,
          quantity: order.quantity,
          description: order.description?.toUpperCase(),
          category: order.category?.toUpperCase(),
        };
        csvData.push(orderItem);
      });
    });
    return csvData;
  };

  const constructCsvDataPendingOrdersNextWeek = (orderData) => {
    let csvData = [];
    orderData.forEach((completeOrder) => {
      if (moment(completeOrder.orderInfo.dateOfVisit).isBetween(moment(), moment().add(1, "w"))) {
        const rowInfoData = {
          inmateCdcr: completeOrder.orderInfo.inmateCdcr,
          inmateName: completeOrder.orderInfo.inmateName,
          visitorName: completeOrder.orderInfo.visitorName,
          dateOfVisit: completeOrder.orderInfo.dateOfVisit,
          totalCost: completeOrder.totalCost,
          status: completeOrder.status,
        };
        csvData.push(rowInfoData);

        completeOrder.order.forEach((order) => {
          const orderItem = {
            item: order.name.toUpperCase(),
            cost: order.cost,
            quantity: order.quantity,
            description: order.description?.toUpperCase(),
            category: order.category?.toUpperCase(),
          };
          csvData.push(orderItem);
        });
      }
    });
    return csvData;
  };

  const constructCsvDataNextWeek = (orderData) => {
    let allOrderedItems = [];

    orderData.forEach((order) => {
      if (moment(order.orderInfo.dateOfVisit).isBetween(moment(), moment().add(1, "w"))) {
        order.order.forEach((orderItem) => {
          const item = {
            item: orderItem.name.toUpperCase(),
            cost: orderItem.cost,
            quantity: parseInt(orderItem.quantity),
            description: orderItem.description?.toUpperCase(),
            category: orderItem.category?.toUpperCase(),
          };

          allOrderedItems.push(item);
        });
      }
    });

    const combinedItems = combineDupeItemQty(allOrderedItems);

    return combinedItems;
  };

  const combineDupeItemQty = (allItems) => {
    const output = allItems.reduce((accumulator, cur) => {
      const item = cur.item;
      const found = accumulator.find((elem) => {
        return elem.item === item;
      });
      if (found) found.quantity += parseInt(cur.quantity);
      else accumulator.push(cur);
      return accumulator;
    }, []);

    return output;
  };

  const csvHeaders = [
    { label: "DATE_OF_VISIT", key: "dateOfVisit" },
    { label: "ORDER_STATUS", key: "status" },
    { label: "INMATE_CDCR", key: "inmateCdcr" },
    { label: "INMATE_NAME", key: "inmateName" },
    { label: "VISITOR_NAME", key: "visitorName" },
    { label: "TOTAL_COST", key: "totalCost" },
    { label: "ITEM", key: "item" },
    { label: "QUANTITY", key: "quantity" },
    { label: "COST", key: "cost" },
    { label: "DESCRIPTION", key: "description" },
    { label: "CATEGORY", key: "category" },
  ];

  const csvHeadersShoppingList = [
    { label: "ITEM", key: "item" },
    { label: "QUANTITY", key: "quantity" },
    { label: "COST", key: "cost" },
    { label: "DESCRIPTION", key: "description" },
    { label: "CATEGORY", key: "category" },
  ];

  // const logMe = () => {
  //   console.log("pendingOrders:", pendingOrders);
  // };

  return (
    <Flex justify="center" alignItems="center" pb={3} zIndex={1} backgroundColor="white">
      <Text
        letterSpacing={3}
        fontSize={18}
        py={2}
        color={showMenu === "pending" ? "blue.300" : "gray.400"}
        cursor="pointer"
        onClick={() => setShowMenu("pending")}
        _hover={{ color: "blue.400" }}
        fontWeight={showMenu === "pending" && 700}
      >
        PENDING
      </Text>
      <Box h={8} mx={4}>
        <Divider orientation="vertical" />
      </Box>
      <Text
        letterSpacing={3}
        fontSize={18}
        py={2}
        color={showMenu === "complete" ? "blue.300" : "gray.400"}
        cursor="pointer"
        onClick={() => setShowMenu("complete")}
        _hover={{ color: "blue.400" }}
        fontWeight={showMenu === "complete" && 700}
      >
        COMPLETED
      </Text>
      <Box h={8} mx={4}>
        <Divider orientation="vertical" />
      </Box>
      <Text
        letterSpacing={3}
        fontSize={18}
        py={2}
        color={showMenu === "all" ? "blue.300" : "gray.400"}
        cursor="pointer"
        onClick={() => setShowMenu("all")}
        _hover={{ color: "blue.400" }}
        fontWeight={showMenu === "all" && 700}
      >
        ALL
      </Text>
      <Box h={8} mx={4}>
        <Divider orientation="vertical" />
      </Box>
      {allOrders ? (
        <Menu>
          <MenuButton letterSpacing={3} as={Button} rightIcon={<AiFillCaretDown />}>
            REPORT
          </MenuButton>
          <MenuList isDisabled={allOrders && allOrders.length < 1}>
            <MenuItem onClick={() => console.log("csvData", constructCsvDataOrders(allOrders))}>
              {allOrders && allOrders.length > 0 ? (
                <CSVLink
                  filename={`cdcr_orders_all_${moment().format("MMDDYYYY")}`}
                  data={constructCsvDataOrders(allOrders)}
                  headers={csvHeaders}
                >
                  <Text>ALL</Text>
                </CSVLink>
              ) : (
                <Text onClick={() => alert("No Data to download.")}>ALL</Text>
              )}
            </MenuItem>
            <MenuItem isDisabled={completeOrders && completeOrders.length < 1}>
              {completeOrders && completeOrders.length > 0 ? (
                <CSVLink
                  filename={`cdcr_orders_complete_${moment().format("MMDDYYYY")}`}
                  data={constructCsvDataOrders(completeOrders)}
                  headers={csvHeaders}
                >
                  <Text>COMPLETED</Text>
                </CSVLink>
              ) : (
                <Text onClick={() => alert("No Data to download.")}>COMPLETED</Text>
              )}
            </MenuItem>
            <MenuItem isDisabled={pendingOrders && pendingOrders.length < 1}>
              {pendingOrders && pendingOrders.length > 0 ? (
                <CSVLink
                  filename={`cdcr_orders_pending_${moment().format("MMDDYYYY")}`}
                  data={constructCsvDataOrders(pendingOrders)}
                  headers={csvHeaders}
                >
                  <Text>PENDING</Text>
                </CSVLink>
              ) : (
                <Text onClick={() => alert("No Data to download.")}>PENDING</Text>
              )}
            </MenuItem>
            <MenuItem isDisabled={pendingOrders && pendingOrders.length < 1}>
              {pendingOrders && pendingOrders.length > 0 ? (
                <CSVLink
                  filename={`cdcr_orders_pending_${moment().format("MMDDYYYY")}`}
                  data={constructCsvDataPendingOrdersNextWeek(pendingOrders)}
                  headers={csvHeaders}
                >
                  <Text>PENDING NEXT WEEK</Text>
                </CSVLink>
              ) : (
                <Text onClick={() => alert("No Data to download.")}>PENDING NEXT WEEK</Text>
              )}
            </MenuItem>
            <MenuItem
              onClick={() => constructCsvDataNextWeek(pendingOrders)}
              isDisabled={pendingOrders && pendingOrders.length < 1}
            >
              {pendingOrders && pendingOrders.length > 0 ? (
                <CSVLink
                  filename={`cdcr_orders_pending_nextweek_${moment().format("MMDDYYYY")}`}
                  data={constructCsvDataNextWeek(pendingOrders)}
                  headers={csvHeadersShoppingList}
                >
                  <Text>NEXT WEEK SHOPPING LIST</Text>
                </CSVLink>
              ) : (
                <Text onClick={() => alert("No Data to download.")}>NEXT WEEK SHOPPING LIST</Text>
              )}
            </MenuItem>
            {/* <MenuItem
              onClick={() => constructCsvDataNextWeek(pendingOrders)}
              isDisabled={pendingOrders && pendingOrders.length < 1}
            >
              {pendingOrders && pendingOrders.length > 0 ? (
                <Text onClick={() => console.log(constructExcelDataOrders(pendingOrders))}>
                  Hello
                </Text>
              ) : (
                <Text onClick={() => alert("No Data to download.")}>NEXT WEEK SHOPPING LIST</Text>
              )}
            </MenuItem> */}
          </MenuList>
        </Menu>
      ) : null}
    </Flex>
  );
}
