import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";

export default function MyInfo({ currentUser, updateUserInfo }) {
  const [name, setName] = useState(currentUser?.name ? currentUser.name : "");
  const [email, setEmail] = useState(currentUser?.email ? currentUser.email : "");
  const [inmateName, setInmateName] = useState(currentUser?.inmateName ? currentUser.inmateName : "");
  const [inmateCdcr, setInmateCdcr] = useState(currentUser?.inmateCdcr ? currentUser.inmateCdcr : "");
  const buttonSize = useBreakpointValue(["sm", "lg"]);
  const inputSize = useBreakpointValue(["sm", "lg"]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSubmit = () => {
    const updatedInfo = {
      name,
      email,
      inmateName,
      inmateCdcr,
    };

    updateUserInfo(updatedInfo);
    onClose();
  };

  return (
    <Flex flexDir="column" mt={5} borderWidth={1} p={5}>
      <Text fontSize={[18, 24]} mb={4} letterSpacing={2}>
        MY INFORMATION
      </Text>
      <Box mb={4}>
        <Text fontSize={[16, 18]}>Name: {name}</Text>
        <Text fontSize={[16, 18]}>Email: {email}</Text>
        <Text fontSize={[16, 18]}>Inmate Name: {inmateName}</Text>
        <Text fontSize={[16, 18]}>Inmate CDCR#: {inmateCdcr}</Text>
      </Box>
      <Button onClick={onOpen} colorScheme="blue" size={buttonSize}>
        Update Information
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir={["column", "row"]} justify="center" alignItems="center">
              <FormControl mb={2}>
                <FormLabel fontSize={[16, 18]}>Name</FormLabel>
                <Input
                  size={inputSize}
                  fontSize={[16, 18]}
                  id="name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
              <FormControl mx={3} mb={2}>
                <FormLabel fontSize={[16, 18]}>Email</FormLabel>
                <Input
                  size={inputSize}
                  fontSize={[16, 18]}
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
            </Flex>
            <Flex flexDir={["column", "row"]} justify="center" alignItems="center">
              <FormControl mb={2}>
                <FormLabel fontSize={[16, 18]}>Inmate Name</FormLabel>
                <Input
                  size={inputSize}
                  fontSize={[16, 18]}
                  id="inmate-name"
                  type="text"
                  value={inmateName}
                  onChange={(e) => setInmateName(e.target.value)}
                />
              </FormControl>
              <FormControl mx={3} mb={2}>
                <FormLabel fontSize={[16, 18]}>Inmate CDCR#</FormLabel>
                <Input
                  size={inputSize}
                  fontSize={[12, 18]}
                  id="inmate-cdcr"
                  type="text"
                  value={inmateCdcr}
                  onChange={(e) => setInmateCdcr(e.target.value)}
                />
              </FormControl>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Update
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}